import React, { useState } from "react";
import styled from "styled-components";
//import { Luminous } from 'luminous-lightbox';
import "luminous-lightbox/dist/Luminous.min.js";
import "luminous-lightbox/dist/luminous-basic.min.css";
import { ignoreShadowTiles } from "../../components/Tiles/mediaLink";
import { isImage, isVideo, getOrigin } from "../../services/fileTypes";
import svgIcons from "../../static/icons";
import MediaCarousel from "../../components/MediaCarousel";
import ReferenceViewModal from "../../containers/ReferenceViewModal";

let MediaThumbnail = React.memo(
  ({
    className,
    media,
    linkedMedia = {},
    small,
    reference,
    carouselList = []
  }) => {
    const origin = media.origin || getOrigin(media.url);
    const isImageMedia = isImage(media.url);
    const isVideoMedia = isVideo(media);
    const isReference = reference || linkedMedia.type === "reference";
    const isDocument = !isReference && !isImageMedia && !isVideoMedia;
    const [openedReference, setOpenedReference] = useState(null);

    const ignoreShadow = ignoreShadowTiles.includes(origin);
    const [activeSlide, setActiveSlide] = useState(null);

    const onShowCarousel = () => {
      setActiveSlide(media);
    };

    const onHideCarousel = () => {
      setActiveSlide(null);
    };

    const onShowReference = () => {
      setOpenedReference(media.id);
    };

    const onHideReference = () => {
      setOpenedReference(null);
    };

    const getImage = () => {
      if (isReference) {
        return svgIcons.reference;
      }

      return isImageMedia ? media.url : svgIcons[origin];
    };

    const onClick = () => {
      if (isReference) {
        return onShowReference();
      }

      if (isDocument) {
        return;
      }

      onShowCarousel();
    };

    return (
      <React.Fragment>
        {activeSlide && (
          <MediaCarousel
            list={carouselList}
            activeItem={activeSlide}
            onClose={onHideCarousel}
          />
        )}
        <ReferenceViewModal
          referenceId={openedReference}
          onClose={onHideReference}
        />
        <div
          className={`${className}${ignoreShadow ? " ignore-shadow" : ""}`}
          style={{ backgroundImage: `url(${getImage()})` }}
          onClick={onClick}
        >
          {!getImage() && (
            <span>
              <i className={`fas fa-link ${small ? "fa-2x" : "fa-3x"}`} />
            </span>
          )}
        </div>
      </React.Fragment>
    );
  }
);

const isHoverable = ({ linkedMedia = {}, media = {}, carouselList = [] }) => {
  const isReference = linkedMedia.type === "reference";
  const isCarousel =
    carouselList.length > 0 && (isImage(media.url) || isVideo(media));

  return isReference || isCarousel;
};

MediaThumbnail = styled(MediaThumbnail)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-color: #37bc9b;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  
  ${props =>
    isHoverable(props) &&
    `
    cursor: pointer;
    
    &:hover {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.7);
    }
  `};

  /* ${props => props.small && `width: 40px; height: 40px;`} */

  &.ignore-shadow {
    background-size: contain;
    background-color: transparent;
    box-shadow: none;
  }

  img {
    height: 100%;
    width: 100% !important;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
  }
`;

export default MediaThumbnail;
