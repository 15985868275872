import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import { createResio } from "../../redux/modules/resios";
import { becomeRecruiter } from "../../redux/modules/auth";

import NewResioForm from "../../containers/NewResioForm";

import { Wrapper, MainContent, Header, SubHeader, CenterHeader, CenterSubHeader } from "./styled";

const Candidate = ({ returnUrl }) => {
  const firstName = useSelector(state => {
    const name = state.auth.user.firstName;
    return name[0].toUpperCase() + name.substring(1);
  });

  const [isVisible, setIsVisible] = useState(true);
  const [text, setText] = useState("");

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
/*
  const toggleResponse = (gptResponse) => {
    setText(gptResponse);

    if (!gptResponse){
      setGptResponseObtained(false);
    } else {
      setGptResponseObtained(true);
    }
  }
*/
  return (
    <React.Fragment>
      <CenterHeader className={isVisible ? '' : 'hidden'}>Welcome to Resio</CenterHeader>
      <Header className={isVisible ? 'hidden' : ''}>Upload your CV</Header>
      <CenterSubHeader className={isVisible ? '' : 'hidden'}>{`It’s great to have you on board ${firstName}`}</CenterSubHeader>
      <SubHeader className={isVisible ? 'hidden' : ''}>{`Drag and drop your files here`}</SubHeader>
      <CenterSubHeader className={isVisible ? '' : 'hidden'}>
        Let’s create your first Resio and start applying for jobs!
        <span role="img">🚀</span>
      </CenterSubHeader>
      <NewResioForm 
      toggleVisibility={toggleVisibility}/>      
    </React.Fragment>
  );
};

class UserTypePick extends React.Component {
  render() {
    const { match } = this.props;

    return (
      <Wrapper>
        <Helmet>
          <title>Welcome</title>
        </Helmet>

        <MainContent style={{ display: "flex" }}>
          <Candidate returnUrl={match.url} />
        </MainContent>
      </Wrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createResio: data => {
    dispatch(createResio(data));
  },
  becomeRecruiter: () => dispatch(becomeRecruiter())
});

UserTypePick = connect(null, mapDispatchToProps)(UserTypePick);

export default UserTypePick;
