import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Field, FieldArray, reduxForm } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { formValueSelector } from "redux-form";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { getReferenceRequest, selectors } from "../../redux/modules/resio"; // TODO: Move to referenceRequest saga

import { provideReferenceRequest } from "../../redux/modules/referenceRequests/referenceRequestsActions";

import contentTypes from "../../constants/contentTypes";
import { months, years } from "../../constants/dates";
import Frame from "../../components/Frame";
import FormButtons from "../../components/FormButtons";
import FullPage from "../../components/FullPage";
import TextField from "../../components/TextField";
import SelectField from "../../components/SelectField";
import DescriptionField from "../../components/DescriptionField";
import ListWithCommentField from "../../components/ListWithCommentField";
import SplitFields from "../../components/SplitFields";
import deleteNulls from "../../services/deleteNulls";
import modals from "../../services/modals";

import {
  required,
  minLength2,
  maxLength2048,
  maxLength512,
  maxLength50
} from "../../services/formValidators";
import Spinner from "../../components/Spinner";

const StyledDateTitle = styled.p`
  margin-top: 25px;
  margin-bottom: 10;
`;

const getMonthOptions = (started = true) =>
  months.map((m, i) => (
    <option key={`${started ? "sm" : "fm"}${m}`} value={i + 1}>
      {m}
    </option>
  ));

const getYearOptions = (started = true) =>
  years.map(m => (
    <option key={`${started ? "sy" : "fy"}${m}`} value={m}>
      {m}
    </option>
  ));

const RenderedDescription = styled.div`
  ol,
  ul {
    padding: 0;
  }
  ol,
  ul > li {
    font-weight: 300;
    word-break: break-word;
    list-style-position: outside;
    margin-left: 1em;
  }
`;

const ProvideReference = ({
  className,
  match,
  pristine,
  invalid,
  handleSubmit,
  isPosting,
  initialize
}) => {
  const referenceId = match.params.id || match.params.referenceId;

  const dispatch = useDispatch();
  const history = useHistory();

  const isFetching = useSelector(selectors.getFetching);
  const referenceRequest = useSelector(selectors.getReferenceRequest);
  const [isInitialized, setIsInitialized] = useState(false);
  const skillset = useSelector(state =>
    formValueSelector("provideReference")(state, "skillset")
  );

  const onSubmitSuccessful = async () => {
    await modals.success({ text: "Thank you for submitting your reference" });
    history.push("/");
  };

  const onSubmit = data => {
    deleteNulls(data);
    const preparedData = {
      ...data,
      skillset: data.skillset
        .filter(skill => skill.comment)
        .map(skill => ({
          title: skill.title,
          checked: skill.checked,
          comment: skill.comment
        }))
    };

    delete preparedData.id;
    delete preparedData.candidateFirstName;
    delete preparedData.message;

    dispatch(
      provideReferenceRequest(referenceId, preparedData, onSubmitSuccessful)
    );
  };

  useEffect(() => {
    if (referenceRequest && !isInitialized) {
      initialize(referenceRequest);
      setIsInitialized(true);
    }
  }, [referenceRequest]);

  useEffect(() => {
    dispatch(getReferenceRequest(referenceId));
  }, [dispatch, referenceId]);

  return (
    <FullPage>
      <Helmet>
        <title>Submit your reference</title>
      </Helmet>

      <Frame
        header="Submit your reference"
        shadow
        headerBorderColor={contentTypes.references.color}
      >
        {isFetching || !referenceRequest ? (
          <Spinner />
        ) : (
          <div className={className}>
            <RenderedDescription>
              <div
                className="message"
                dangerouslySetInnerHTML={{ __html: referenceRequest.message }}
              />
            </RenderedDescription>
            <p>
              Please check your detail are correct and make any necessary
              amendments
            </p>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Field
                name="firstName"
                component={TextField}
                label="Referee First Name"
                validate={[required, minLength2, maxLength2048]}
                required
              />

              <Field
                name="lastName"
                component={TextField}
                label="Referee Last Name"
                validate={[required, minLength2, maxLength2048]}
                required
              />

              <Field
                name="jobTitle"
                component={TextField}
                label="Referee Job Title"
                validate={[required, minLength2, maxLength2048]}
                required
              />

              <Field
                name="organisation"
                component={TextField}
                label="Referee Organisation"
                validate={[required, minLength2, maxLength2048]}
                required
              />

              <p>
                How do you know {referenceRequest.candidateFirstName}? Please
                amend if necessary
              </p>

              <Field
                name="connection"
                component={SelectField}
                label="How do you know them?"
                required
              >
                <option value="" disabled />
                <option value="workedForMe">I worked for them</option>
                <option value="workedForThem">They worked for me</option>
                <option value="workedTogether">We worked together</option>
              </Field>

              <p>Was this their Job Title? Please amend if necessary</p>

              <Field
                name="candidateJobTitle"
                component={TextField}
                label="Job Title"
                validate={[required, minLength2, maxLength2048]}
                required
              />

              <StyledDateTitle>
                Between what dates? Please amend if necessary
              </StyledDateTitle>
              <SplitFields>
                <Field
                  name="startMonth"
                  component={SelectField}
                  label="Month Started"
                >
                  <option value="null">Month</option>
                  {getMonthOptions()}
                </Field>
                <Field
                  name="startYear"
                  component={SelectField}
                  label="Year Started"
                >
                  <option value="null">Year</option>
                  {getYearOptions()}
                </Field>
              </SplitFields>
              <SplitFields>
                <Field
                  name="finishMonth"
                  component={SelectField}
                  label="Month Finished"
                >
                  <option value="null">Month</option>
                  {getMonthOptions(false)}
                </Field>
                <Field
                  name="finishYear"
                  component={SelectField}
                  label="Year Finished"
                  placeholder="Year"
                >
                  <option value="null">Year</option>
                  {getYearOptions(false)}
                </Field>
              </SplitFields>

              <h3>Reference</h3>

              <Field
                name="reference"
                className="reference"
                component={DescriptionField}
                placeholder="Please write your reference here"
                multiline="true"
                label="Reference"
                validate={[required, minLength2, maxLength2048]}
                normalize={value => value.slice(0, 2048)}
                required
              />

              {skillset && !!skillset.length && (
                <p>
                  Can you verify that {referenceRequest.candidateFirstName}{" "}
                  demonstrated the following skills? If so, please click the
                  tick box and comment
                </p>
              )}

              <FieldArray
                heading={<h3 className="work-skills">Work Skills</h3>}
                name="skillset"
                rerenderOnEveryChange
                component={ListWithCommentField}
                itemPredicate={item => item.type === "workSkill"}
                validationSchema={{
                  comment: [minLength2, maxLength512]
                }}
                normalizationSchema={{
                  comment: value => value.slice(0, 512)
                }}
              />

              <FieldArray
                heading={<h3 className="soft-skills">Interpersonal Skills</h3>}
                name="skillset"
                rerenderOnEveryChange
                component={ListWithCommentField}
                itemPredicate={item => item.type === "softSkill"}
                validationSchema={{
                  comment: [minLength2, maxLength512]
                }}
                normalizationSchema={{
                  comment: value => value.slice(0, 512)
                }}
              />

              <FormButtons
                submitLabel="Submit"
                submitDisabled={pristine || invalid}
                loading={isPosting}
              />
            </form>
          </div>
        )}
      </Frame>
    </FullPage>
  );
};

const StyledProvideReference = styled(ProvideReference)`
  p {
    margin: 14px 0;
    font-size: 14px;
  }

  h3 {
    margin: 14px 0;
    font-weight: 500;
    color: #656d78;
    font-size: 14px;

    &.work-skills {
      color: #c772ea;
    }

    &.soft-skills {
      color: #967adc;
    }
  }

  .message {
    resize: vertical;
    border: 0.5px solid #979797;
    padding: 12px;
    font-weight: 300;

    p {
      margin: 0;
    }
  }

  .reference {
    min-height: 86px;
    resize: vertical;
    font-weight: normal;
    color: #656d78;

    &::placeholder {
      color: #a7a7a7;
    }
  }
`;

export default reduxForm({
  form: "provideReference",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  initialValues: {
    startMonth: "null",
    startYear: "null",
    finishMonth: "null",
    finishYear: "null"
  }
})(StyledProvideReference);
