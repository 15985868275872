import React, { Component } from "react";
import styled from "styled-components";
import Raven from "raven-js";
import Button from "../Button";
import FullWidth from "../FullWidth";
import logo from "../../static/logo_grey.png";
import logo_beta from "../../static/logo_beta_grey.png";

const BannerLogo = ({ className }) => (
  <a href="/" className={className}>
    <img src={logo_beta} alt="logo" />
  </a>
);

const StyledBannerLogo = styled(BannerLogo)`
  outline: 0;
  width: 200px;
  display: block;
  margin-bottom: 1rem;

  > img {
      width: 160px;
  height: 40px;
  }
`;

class BigErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
    this.showReportDialog = this.showReportDialog.bind(this);
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Raven.captureException(error, { extra: errorInfo });
  }

  showReportDialog() {
    Raven.lastEventId();
    Raven.showReportDialog();
  }

  render() {
    if (this.state.error) {
      return (
        <FullWidth>
          <StyledBannerLogo />
          <h3>Something has gone wrong!</h3>
          <p>
            Our team has been notified, but it would help us a lot if you gave
            us some more information.
          </p>
          <Button
            primary
            onClick={this.showReportDialog}
            style={{ marginBottom: "1rem" }}
          >
            Fill out a report
          </Button>
          <Button primary element="a" href="/" style={{ marginBottom: "1rem" }}>
            Go back
          </Button>
        </FullWidth>
      );
    }

    return this.props.children;
  }
}

export default BigErrorBoundary;
