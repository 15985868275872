import React from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import find from "lodash/find";
import RouteLeavingGuard from "../../components/CustomPrompt";
import { updateResio, selectors } from "../../redux/modules/resio";
import { GoBackButton } from "../../components/GoBackButton";

import softSkills from "../../constants/softSkills";
import contentTypes from "../../constants/contentTypes";
import Frame from "../../components/Frame";
import FormButtons from "../../components/FormButtons";
import SelectField from "../../components/SelectField";
import CreateField from "../../components/CreateField";
import TextEditor from "../../components/TextEditor";
import { v4 as uuidv4 } from 'uuid';


class AddSoftSkill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dirt: false,
      softSkillDescription: "", 

    };
    this.onSubmit = this.onSubmit.bind(this);
  }
  handleDescriptionChange = (event) => {
    const { value } = event.target;
    this.setState({ softSkillDescription: value, dirt: true });
  };
  componentDidMount() {
    const { initialValues } = this.props;
    if (initialValues.softSkillDescription) {
      this.setState({ softSkillDescription: initialValues.softSkillDescription });
    }
  }
  
  componentDidUpdate(prevProps) {
    if (
      prevProps.initialValues.softSkillDescription !==
      this.props.initialValues.softSkillDescription
    ) {
      this.setState({
        softSkillDescription: this.props.initialValues.softSkillDescription,
      });
    }
  }
  
  

  setIsEditing = value => {
    this.setState({ dirt: value });
  };
  async onSubmit(data) {
    this.setIsEditing(false);
  
  
  
    const targetIndex = Number(this.props.index); // Ensure it's a number
  
    const update = {
      ...this.props.resio,
      softSkills: {
        updatedSoftSkills: this.props.resio.softSkills.updatedSoftSkills.map((e, index) => {
          if (index !== targetIndex) {
            return e; // Keep other items unchanged
          }
  
          return {
            ...e,
            id: e.id || uuidv4(), // Preserve existing ID if available
            title: data.skills.value || e.title, // Keep existing title if not updated
            softSkillDescription: this.state.softSkillDescription ?? e.softSkillDescription // Preserve existing description if not updated
          };
        }),
        OverallDescription: this.props.resio.softSkills.OverallDescription // Preserve OverallDescription
      }
    };
  
    try {
      await this.props.updateResio(this.props.resio.id, update);
      this.props.history.push(
        `/edit/${this.props.resio.id}${contentTypes.softSkills.url}`
      );
    } catch (error) {
      console.error("Error updating Resio:", error);
    }
  }
  

  render() {
    const {
      pristine,
      invalid,
      resio,
      title,
      handleSubmit,
      isPosting,
      skills,
      selectedItems
    } = this.props;

    const skillsFormatted = [
      {
        value: "",
        label: "Start typing or scroll to select a soft skillset",
        isDisabled: true
      },
      ...softSkills.map(skillset => ({
        label: skillset.title,
        options: skillset.items
          ? skillset.items.map(skill => ({
            value: skill,
            label: skill,
            group: skillset.title,
            // isDisabled: selectedItems.includes(skill) || isMaxReached
          }))
          : []
      }))
    ];


    return (
      <>
        <GoBackButton onClick={() => this.props.history.goBack()} />
        <Frame
          header={contentTypes.softSkills.title}
          shadow
          headerBorderColor={contentTypes.softSkills.color}
        >
          <Helmet>
            <title>{contentTypes.softSkills.title}</title>
          </Helmet>
          <RouteLeavingGuard
            when={!!this.state.dirt}
            shouldBlockNavigation={() => !!this.state.dirt}
          />
          <form
            onChange={() => {
              this.setIsEditing(true);
            }}
            onSubmit={handleSubmit(this.onSubmit)}
          >
            {/* <Field name="title" component={SelectField} label="Title">
              <option value="null" disabled>
                Skill Set Type
              </option>
              {softSkills.map(ps => (
                <option key={ps.title} value={ps.title}>
                  {ps.title}
                </option>
              ))}
            </Field> */}

            <Field
              name="skills"
              component={CreateField}
              label="Skills"
              options={skillsFormatted}
              isMulti={false}
              // isDisabled={isMaxReached}
              // error={this.state.error}
              placeholder="Select up to 10 skills"
            />
            
         <div style={dFlex}>
  <label style={{ width: "100%" }}>
    <span style={{ display: "block", fontWeight: "400",
    fontSize:" 1rem", marginBottom: "5px", color:" #a7a7a7" }}>
      Description
    </span>
    <textarea 
    value={this.state.softSkillDescription}
    onChange={(event) => {
      this.setState({ softSkillDescription: event.target.value });
      this.props.change("softSkillDescription", event.target.value); // ✅ Register in Redux Form
    }}
      placeholder="Add a short description to convey your proficiency in this interpersonal skill - 100 characters maximum"
      maxLength={100}
      style={{
        width: "100%",
        minHeight: "100px",
        padding: "10px",
        fontSize: "14px",
        border: "1px solid lightgrey",
        borderRadius: "5px",
        fontFamily: "Mulish",
        marginRight: "30px",
         marginBottom: "10px"
      }}
    />
  </label>
</div>
            <FormButtons
              cancelUrl={`/edit/${resio.id}${contentTypes.softSkills.url}`}
              submitDisabled={pristine || invalid}
              loading={isPosting}
            />
          </form>
        </Frame>
      </>
    );
  }
}

const selector = formValueSelector("editSoftSkill");

const mapStateToProps = (state, props) => {
  const title = selector(state, "title");
  const skills = softSkills.find(ps => ps.items.includes(title));
  const resio = selectors.getResio(state);
  let foundSkillItem = null;

  if (skills) {
    foundSkillItem = skills.items.find(item => item === title);
  }

  // Ensure index is correctly parsed as a number
  const targetIndex = Number(props.index);

  // Fetch the existing description from updatedSoftSkills if it exists
  const existingSoftSkillDescription =
    resio?.softSkills?.updatedSoftSkills?.[targetIndex]?.softSkillDescription || "";
  
  return {
    resio,
    title,
    skills,
    foundSkillItem,
    isPosting: selectors.getPosting(state),
    initialValues: {
      title: props.initialValues?.title || "",
      softSkillDescription: existingSoftSkillDescription, // Prepopulate with existing value
      skills: foundSkillItem
        ? [{ label: foundSkillItem, value: foundSkillItem }]
        : []
    }
  };
};


const mapDispatchToProps = dispatch => ({
  updateResio: (id, data) =>
    new Promise((res, rej) => {
      dispatch(updateResio(id, data, res, rej));
    })
});

 //description style
 const dFlex = {
  display: "flex",
  alignItems: "center",
  gap: "2px",
};
AddSoftSkill = reduxForm({
  form: "editSoftSkill",
  enableReinitialize: true
})(AddSoftSkill);

AddSoftSkill = withRouter(AddSoftSkill);

AddSoftSkill = connect(mapStateToProps, mapDispatchToProps)(AddSoftSkill);

export default AddSoftSkill;
