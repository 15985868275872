import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { Route, Link } from "react-router-dom";
import ClickOutside from "react-click-outside";
import { useSelector } from "react-redux";

import Button from "../../components/Button";
import ProfileDropdown from "../ProfileDropdown";
import ResioBrowser from "../../containers/ResioBrowser";
import { media } from "../../styles";
import { selectors } from "../../redux/modules/resio";
import { selectors as selectorsAuth } from "../../redux/modules/auth";
import { selectors as resioSelectors } from "../../redux/modules/resios";

const SplitButtons = styled(({ className, children }) => (
  <div className={className}>{children}</div>
))`
  display: none;
  ${media.tablet`display: flex;`};

  > * {
    margin: 0 14px 0 0;

    &:last-child {
      margin: 0;
    }
  }
`;

const ResioBrowserToggle = styled(({ className, onClick, open }) => (
  <button type="button" className={className} onClick={onClick}>
    My Resios
    <span key={open}>
      <i className={`fas fa-fw fa-chevron-${open ? "up" : "down"}`} />
    </span>
  </button>
))`
  color: #fff;
  outline: 0;
  border: 0;
  background: none;
  cursor: pointer;
  font-family: "Mulish", sans-serif;
  font-size: 1.1rem;
  white-space: nowrap;
  vertical-align: middle;
  padding: 0;
  margin-right: 1rem;

  > span {
    vertical-align: middle;
    margin-left: 0.5rem;
  }
`;

const BannerLoggedIn = ({ className }) => {
  const { id } = useSelector(selectors.getResio);
  const hasResios = useSelector(resioSelectors.hasResios);
  const firstName = useSelector(state => {
    if (selectorsAuth.getFirstName(state)) {
      return selectorsAuth.getFirstName(state);
    }

    return state?.resio?.resio?.firstName || "";
  });

  const organisationName = useSelector(selectorsAuth.getOrganisationName);

  const [showResioBrowser, setShowResioBrowser] = useState(false);
  const buttonRef = useRef();

  const toggle = () =>
    setShowResioBrowser(prevState => setShowResioBrowser(!prevState));

  const clickOutside = e => {
    const isClickedOnToggle = buttonRef.current.contains(e.target);
    !isClickedOnToggle && setShowResioBrowser(false);
  };

  return (
    <div className={className}>
      {hasResios ? (
        <div ref={buttonRef}>
          <ResioBrowserToggle
            isOpen={showResioBrowser}
            onClick={toggle}
            open={showResioBrowser}
            key={showResioBrowser}
          />
        </div>
      ) : (
        <div />
      )}
      <SplitButtons>
        <Button
          element={Link}
          to={`/${id}`}
          svgIcon="iconEye"
          outline
          condensed
          header
        >
          Preview
        </Button>
        <Button
          element={Link}
          to={`/edit/${id}/share`}
          svgIcon="iconShare"
          outline
          condensed
          header
        >
          Apply/Share
        </Button>
      </SplitButtons>

      <ProfileDropdown
        firstName={`${firstName}${
          organisationName ? ` (${organisationName})` : ""
        }`}
      />
     

      <Route
        path="/:resioId"
        render={({ match }) =>
          showResioBrowser &&
          ReactDOM.createPortal(
            // <ClickOutside onClickOutside={clickOutside}>
              <ResioBrowser match={match} toggleBrowser={toggle} />
            // </ClickOutside>
            ,
            document.body
          )
        }
      />
    </div>
  );
};

export default styled(BannerLoggedIn)`
  display: flex;
  flex-flow: row;
  width: 100%;
  align-items: center;
`;
