import React, { isValidElement } from "react";
import moment from "moment";
import {
  array,
  bool,
  string,
  shape,
  oneOfType,
  node,
  instanceOf
} from "prop-types";
import styled from "styled-components";

import {
  Container,
  Sidebar,
  Content,
  Separator,
  TitleStyled,
  InfoStringStyled,
  NoBrake
} from "./Layout";
import MediaSidebar from "./MediaSidebar";
import MediaDropdown from "./MediaDropdown";
import icons from "../../static/icons";
import { media, mediaReverse } from "../../styles";
import icon_location from "../../static/icons/icon-location.svg";
import icon_calender from "../../static/icons/calendar.svg"
const RESPONSIVE = {
  desktopOnly: "desktopOnly",
  mobileOnly: "mobileOnly"
};
const IconWrapper = ({ src }) => (
  <div
    style={{
      width: "34px",
      height: "34px",
      backgroundImage: `url(${src})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "1px 4px",
      backgroundSize: "24px",
    }}
  />

);
const RenderedDescription = styled.div`
  ol,
  ul {
    padding: 0;
    margin: 0;
  }
  ol,
  ul > li {
    font-weight: 300;
    word-break: break-word;
    list-style-position: outside;
    margin-left: 1em;
  }
`;

const ResponsiveWrapper = styled.div`
  display: ${props =>
    props.config === RESPONSIVE.desktopOnly ? "none" : "block"};

  ${media.tablet`
      display: ${props =>
      props.config === RESPONSIVE.mobileOnly ? "none" : "block"};
  `}

  @media print {
    ${mediaReverse.tablet`
      display: ${props =>
      props.config === RESPONSIVE.mobileOnly ? "none" : "block"};
    `}
  }

  &:first-child > h2 {
    word-break: break-word;

    ${mediaReverse.desktop`
      margin-bottom: 2px;
    `}
  }
`;

/**
 * @param {string|node} title - Entry title (string or node)
 */
const renderTitle = (title, config) => (
  <ResponsiveWrapper config={config}>
    {isValidElement(title) ? title : <TitleStyled>{title}</TitleStyled>}
  </ResponsiveWrapper>
);

/**
 * @param {string|node} subTitle - Entry subtitle (string or node)
 */
const renderSubTitle = (subTitle, config) => (
  <ResponsiveWrapper config={config}>
    {isValidElement(subTitle) ? (
      subTitle
    ) : (
      <TitleStyled>{subTitle}</TitleStyled>
    )}
  </ResponsiveWrapper>
);

const propTypes = {
  item: shape({
    title: string,
    subTitle: oneOfType([string, node]),
    description: oneOfType([string, node]),
    startDate: instanceOf(moment),
    finishDate: instanceOf(moment),
    location: string,
    linkedMedia: array
  }).isRequired,
  fixedDate: bool,
  final: bool,
  accentColor: string
};

const defaultProps = {
  fixedDate: false,
  final: false,
  accentColor: null
};

const TimelineEntry = ({ item, fixedDate, final, accentColor }) => {
  const showDropdown = item.linkedMedia && item.linkedMedia.length > 2;
  return (
    <React.Fragment>
      <Container style={{ display: 'block' }}>
      <Sidebar style={{ display: 'flex', flexWrap: 'wrap', paddingBottom: '0', justifyContent: 'space-between', width: '100%' }}>
  <div style={{ display: 'flex', alignItems:'center',  gap: "10px" }}>
    {item.subTitle && renderTitle(item.subTitle)}
    <span style={{ fontSize: 12, color: accentColor }}>|</span>
    {item.title && renderSubTitle(item.title, RESPONSIVE.desktopOnly)}
  </div>

  {/* Removed the extra renderSubTitle call here */}
  
  {item.startDate && (
    <InfoStringStyled>
      <NoBrake class="no-brake">
        <img src={icon_calender} alt="Date" />
      </NoBrake>
      <div>
        {item.startDate.format("MMM YYYY")}
        {!fixedDate && (
          <React.Fragment>
            {" "} - {" "}
            {item.finishDate ? item.finishDate.format("MMM YYYY") : "Present"}
          </React.Fragment>
        )}
      </div>
    </InfoStringStyled>
  )}

  {item.linkedMedia && !showDropdown && (
    <MediaSidebar
      linkedMedia={item.linkedMedia}
      className="d-none d-lg-block"
    />
  )}
</Sidebar>

        {/* <Separator final={final && !showDropdown} accentColor={accentColor} /> */}
        <Content>
          {/* <div style={{ display: 'flex', alignItems: 'center', }}>  <IconWrapper src={icon_location} />  <span></span></div> */}
          {/* {item.title &&
            renderSubTitle(item.title, RESPONSIVE.desktopOnly)} */}
          {item.location && (
            <InfoStringStyled>
              <i style={{height:'0.85rem', marginRight:'5px'}} className="fas fa-map-marker-alt fa-fw" />
             <span> {item.location}</span>
            </InfoStringStyled>
          )}

          {isValidElement(item.description) ? (
            item.description
          ) : (
            <RenderedDescription>
              <div dangerouslySetInnerHTML={{ __html: item.description }} />
            </RenderedDescription>
          )}
          {item.linkedMedia && !showDropdown && (
            <MediaSidebar
              linkedMedia={item.linkedMedia}
              accentColorText
              className="d-lg-none"
            />
          )}
        </Content>
      </Container>
      {showDropdown && (
        <MediaDropdown final={final} linkedMedia={item.linkedMedia || []} />
      )}
    </React.Fragment>
  );
};

TimelineEntry.propTypes = propTypes;
TimelineEntry.defaultProps = defaultProps;

export default TimelineEntry;
