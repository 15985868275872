import axios from "../services/axios";

export function get(id) {
  return axios.get(`/resio/${id}`, { withCredentials: true }).then(resp => {
    return resp.data;
  });
}

export function getAll() {
  return axios.get("/resio", { withCredentials: true }).then(resp => {
    return resp.data;
  });
}

export function getMine() {
  return axios.get("/resio/me", { withCredentials: true }).then(resp => {
    return resp.data;
  });
}

export function getShared() {
  return axios.get("/resio/shared", { withCredentials: true }).then(resp => {
    return resp.data;
  });
}

export function removeShared(id) {
  return axios
    .delete(`/resio//${id}`, { withCredentials: true })
    .then(resp => resp.data);
}

export function inviteShare(emails) {
  return axios
    .post("/resio/shared", emails, { withCredentials: true })
    .then(resp => resp.data);
}

export function getAccess(id) {
  return axios
    .get(`/resio/${id}/access`, { withCredentials: true })
    .then(resp => {
      return resp.data;
    });
}

export function update(id, data) {
  console.log(data,"data@@@");
  return axios
    .post(`/resio/${id}`, data, { withCredentials: true })
    .then(resp => resp.data);
}

export function updateAccess(
  id,
  emails,
  subject,
  message,
  attachCV,
  blindCopy
) {
  return axios
    .post(
      `/resio/${id}/access`,
      { emails, subject, message, attachCV, blindCopy },
      { withCredentials: true }
    )
    .then(resp => resp.data);
}

export function sendTestAccess(id, subject, message, attachCV) {
  return axios
    .post(
      `/resio/${id}/test-access`,
      { subject, message, attachCV },
      { withCredentials: true }
    )
    .then(resp => resp.data);
}

export function create(data) {
  const formData = new FormData();

  for (let key in data) {
    formData.append(key, data[key]);
  }

  return axios
    .post("/resio", data, { withCredentials: true }, {
      headers: {
        "x-csrf-token": window.sessionStorage.getItem("CSRF-TOKEN")
      },
    })
    .then(resp => resp.data);
}

export function fill(data){
  return axios
    .post("/resio/fill", data, {withCredentials: true})
    .then(resp => resp.data)
}

export function getMedia(id, mediaId) {
  const response = axios
    .get(`/resio/${id}/media/${mediaId}`)
    .then(resp => resp.data);
  return response;
}

export function uploadMedia(
  id,
  mediaId,
  file,
  name,
  description,
  isMediaPrivate = false
) {
  const data = new FormData();
  data.append("file", file);
  data.append("name", name);
  description && data.append("description", description);

  return axios
    .post(`/resio/${id}/media/${isMediaPrivate}/${mediaId || ""}`, data, {
      withCredentials: true
    })
    .then(resp => resp.data);
}

export function updateProfilePicture(id, file, url) {
  const data = new FormData();

  if (file) {
    data.append("file", file);
  }

  data.append("url", url);

  return axios
    .post(`/resio/${id}/profilePicture`, data, {
      withCredentials: true
    })
    .then(resp => resp.data);
}

export function deleteProfilePicture(id) {
  return axios
    .delete(`/resio/${id}/profilePicture`, {
      withCredentials: true
    })
    .then(resp => resp.data);
}

export function addOrganisationAccess(resioId, organisationId) {
  return axios
    .post(
      `/resio/${resioId}/access/organisation`,
      { organisationId },
      { withCredentials: true }
    )
    .then(resp => resp.data);
}

export function getReferenceRequest(requestId) {
  return axios.get(`/resio/reference/${requestId}`).then(resp => {
    return resp.data;
  });
}

export function provideReference(requestId, data) {
  return axios
    .post(`/resio/reference/${requestId}`, data)
    .then(resp => resp.data);
}
