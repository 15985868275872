import React, {useEffect, useState} from "react";
import styled from "styled-components";

const PercentageSpinner = ({className}) => {
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        const maxTime = 90;
        const interval = setInterval(() => {
            setPercentage((prev) => Math.min(prev + (100 / maxTime), 100));
        }, 1000);

        return () => clearInterval(interval); // Vyčištění časovače
    }, []);

    return (
        <div className={className}>
            <div className="spinner"></div>
            <div className="percentage">{percentage.toFixed(0)}%</div>
        </div>
    );
};

export default styled(PercentageSpinner)`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 200px;

    .spinner {
        width: 10em;
        height: 10em;
        margin: 0;
        border-radius: 50%;
        font-size: 10px;
        border-top: 1.1em solid rgba(55, 188, 155, 0.2);
        border-right: 1.1em solid rgba(55, 188, 155, 0.2);
        border-bottom: 1.1em solid rgba(55, 188, 155, 0.2);
        border-left: 1.1em solid #37bc9b;
        animation: load8 1.1s infinite linear;
        position: absolute; /* Zajišťuje, že spinner nebude ovlivňovat text */

        @keyframes load8 {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }

    .percentage {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 18px;
        font-weight: bold;
        color: gray; /* Nastavení šedé barvy pro text */
        z-index: 1; /* Ujistíme se, že text bude nad spinnerem */
    }
`;