import styled from "styled-components";

export const AddButtonText = styled.span`
  margin-top: 18px;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #777478;
`;
