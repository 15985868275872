import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import omit from "lodash/omit";

import { tileTypesKeysMap } from "../../constants/tileTypes";

const reorder = (arr, startIndex, endIndex) => {
  const result = Array.from(arr);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const useFrameDnd = (tileType, typeSelector, resio, tiles, updateResio) => {
  let itemsRedux = useSelector(typeSelector);

  // Ensure itemsRedux is always an array
  if (!Array.isArray(itemsRedux)) {
    itemsRedux = Object.values(itemsRedux || {}); // Convert object to array if needed
  }


  const [items, setItems] = useState([]);

  useEffect(() => {

    // 🔍 Flatten itemsRedux properly
    const flatItemsRedux = itemsRedux.flat();

    // 🔄 Create a new reference every time
    const newItems = flatItemsRedux.map((item, idx) => ({
      ...item,
      index: idx,
      isEnabled: tiles.some(({ type, index }) => type === tileType && index === idx),
    }));


    setItems((prevItems) => {
      const isDifferent = JSON.stringify(prevItems) !== JSON.stringify(newItems);
      if (isDifferent) {

        return [...newItems]; // 🔥 Forces React to detect changes
      }

      return prevItems;
    });

  }, [JSON.stringify(itemsRedux), JSON.stringify(tiles), tileType]);  // 🔥 Ensures effect runs when dependencies change





  const listDragEnd = (result) => {
    if (!result.destination || result.destination.index === result.source.index) {
      return;
    }
  
    const reorderedList = reorder(items, result.source.index, result.destination.index);
    setItems(reorderedList);
  
    const updatedTiles = [
      ...tiles.filter(({ type }) => type !== tileType),
      ...reorderedList
        .map(({ isEnabled, index }) => (isEnabled ? { type: tileType, index } : null))
        .filter(Boolean),
    ];
  
    const key = tileTypesKeysMap.get(tileType);
    let updatedData;
  
    if (Array.isArray(resio[key])) {
      updatedData = reorderedList
        .filter((item) => item.id) // ✅ Filter out items without an ID
        .map((item) => omit(item, "isEnabled", "index"));
    } else if (typeof resio[key] === "object" && resio[key] !== null) {
      updatedData = {
        ...resio[key],
        updatedSoftSkills: reorderedList
          .filter((item) => item.id) // ✅ Ensure no invalid items in updatedSoftSkills
          .map((item) => omit(item, "isEnabled", "index")),
      };
    } else {
      updatedData = reorderedList;
    }
  
    const update = {
      ...resio,
      [key]: updatedData,
      tiles: updatedTiles,
    };
  
    updateResio(resio.id, update);
  };
  
  
  

  return {
    items,
    listDragEnd,
  };
};