import React from "react";
import styled from "styled-components";

const SectionCard = ({ className, title, children }) => {
 

  return (
    <div className={className}>
      {title ? <h2>{title}</h2> : null}
      {children}
    </div>
  );
};

export default styled(SectionCard)`
  position: relative;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.237762);

  border-radius: 18px;
  padding: 28px;
  margin-top: 20px;
  min-height: 330px;

  &:last-child {
    margin-bottom: 20px;
  }

  & > h2 {
    position: absolute;
    top: -34px;

    font-weight: 400;
    font-size: 18px;
    color: ${props => {
      return props.titleColor || "#000;";
    }};
    
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  ${props => {
    return props.title
      ? `
    margin-top: 60px;
  `
      : "";
  }}

  ${props => {
    return props.small
      ? `
    border-radius: 12px;
    padding: 20px;
    min-height: auto;
  `
      : "";
  }}
`;
