import { put, call, takeLatest, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import get from "lodash/get";

import * as constants from "./referenceRequestsConstants";
import * as actions from "./referenceRequestsActions";
import { referenceRequests as api } from "../../../api";
import { updateResio, selectors as resioSelectors } from "../resio";

import errorHandlers from "../../../services/errorHandlers";
import modals from "../../../services/modals";
import TileTypes from "../../../constants/tileTypes";

function* onRequestReferenceRequests(action) {
  try {
    const data = yield call(api.getReferenceRequests, action.id);
    yield put(actions.requestReferenceRequestsSuccess(data));
  } catch (error) {
    if (
      !(
        get(error, "response.status") === 403 ||
        get(error, "response.status") === 401
      )
    ) {
      yield call(errorHandlers.report, error);
    }
    yield put(actions.requestReferenceRequestsFail(error));
  }
}

function* onCreateReferenceRequest(action) {
  try {
    const data = yield call(
      api.createReferenceRequest,
      action.resioId,
      action.data
    );
    yield put(actions.createReferenceRequestSuccess(data));

    window.mixpanel.track("User requested a reference");
    if (action.resolve) action.resolve();

    yield call(modals.success, { text: "Reference requested!" });
  } catch (error) {
    if (get(error, "response.status") === 403) {
      yield put(push("/login"));
    } else {
      yield call(errorHandlers.report, error);
      yield put(actions.createReferenceRequestFail(error));

      if (action.reject) action.reject();

      yield call(errorHandlers.showDialog);
    }
  }
}

function* onDeleteReferenceRequest(action) {
  try {
    yield call(api.deleteReferenceRequest, action.resioId, action.id);
    yield put(actions.deleteReferenceRequestSuccess(action.id));
  } catch (error) {
    if (get(error, "response.status") === 403) {
      yield put(push("/login"));
    } else {
      yield call(errorHandlers.report, error);
      yield put(actions.deleteReferenceRequestFail(error));
      yield call(errorHandlers.showDialog);
    }
  }
}

function* onProvideReferenceRequest(action) {
  try {
    const data = yield call(
      api.provideReferenceRequest,
      action.id,
      action.data
    );

    yield put(actions.provideReferenceSuccess(data));

    if (action.resolve) action.resolve();
  } catch (error) {
    if (get(error, "response.status") === 403) {
      yield put(push("/"));
    } else {
      yield call(errorHandlers.report, error);

      yield put(actions.provideReferenceRequestFail(error));
      if (action.reject) action.reject();

      yield call(errorHandlers.showDialog);
    }
  }
}

function* onPublishReference(action) {
  const resio = yield select(resioSelectors.getResio);

  const updatedReferences = resio.references.map(
    (reference, referenceIndex) => {
      if (referenceIndex === action.index) {
        return {
          ...reference,
          published: !reference.published
        };
      }

      return reference;
    }
  );

  let updatedTiles = resio.tiles;

  if (updatedReferences[action.index].published === false) {
    updatedTiles = resio.tiles.filter(
      tile =>
        !(tile.type === TileTypes.reference && tile.index === action.index)
    );
  }

  const updatedData = {
    ...resio,
    references: updatedReferences,
    tiles: updatedTiles
  };

  yield put(
    updateResio(
      resio.id,
      updatedData,
      action.resolve,
      action.reject,
      action.suppressModal
    )
  );
}

function* onGetReferenceEmailPreview(action) {
  try {
    const data = yield call(
      api.getReferenceEmailPreview,
      action.resioId,
      action.data
    );

    yield put(actions.getReferenceEmailPreviewSuccess(data));

    if (action.resolve) action.resolve();

    yield call(modals.iframe, {
      content: data,
      background: "#F5F7FA",
      showCloseButton: true
    });
  } catch (error) {
    if (get(error, "response.status") === 403) {
      yield put(push("/login"));
    } else {
      yield call(errorHandlers.report, error);
      yield put(actions.getReferenceEmailPreviewFail(error));

      if (action.reject) action.reject();

      yield call(errorHandlers.showDialog);
    }
  }
}

export default function* referenceRequestsSaga() {
  yield takeLatest(
    constants.REQUEST_REFERENCE_REQUESTS,
    onRequestReferenceRequests
  );
  yield takeLatest(constants.CREATE_REFERENCE_REQUEST, onCreateReferenceRequest);
  yield takeLatest(constants.DELETE_REFERENCE_REQUEST, onDeleteReferenceRequest);
  yield takeLatest(
    constants.PROVIDE_REFERENCE_REQUEST,
    onProvideReferenceRequest
  );
  yield takeLatest(
    constants.GET_REFERENCE_EMAIL_PREVIEW,
    onGetReferenceEmailPreview
  );
  yield takeLatest(constants.PUBLISH_REFERENCE, onPublishReference);
}
