import React, { useCallback, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import uniqueId from "lodash/uniqueId";
import { useHistory } from "react-router-dom";
import {
  updateResio as updateResioAction,
  selectors
} from "../../redux/modules/resio";

import modals from "../../services/modals";
import { GoBackButton } from "../../components/GoBackButton";
import TileTypes from "../../constants/tileTypes";
import contentTypes from "../../constants/contentTypes";
import Frame from "../../components/Frame";
import FrameList from "../../components/FrameList";
import FrameListItem from "../../components/FrameListItem";
import FrameListAdd from "../../components/FrameListAdd";
import { useFrameDnd } from "../../services/hooks";
import { SubHeader } from "./styled";
import StyledButton from "../../components/StyledButton";
import iconTile from "../../static/icons/icon-tile.svg";
import iconTileActive from "../../static/icons/icon-tile-active.svg";

const SoftSkills = ({ match }) => {
  const resio = useSelector(selectors.getResio);
  const tiles = useSelector(selectors.getAllTiles);
  const history = useHistory();
  const dispatch = useDispatch();
  const updateResio = useCallback(
    (id, data) => {
      dispatch(updateResioAction(id, data, null, null, true));
    },
    [dispatch]
  );
  const overallDescription = resio?.softSkills?.OverallDescription?.[0]?.description || "";

  //description style
  const dFlex = {
    display: "flex",
    alignItems: "center",
    gap: "2px",
  };


  const containerNote = {
    padding: "20px",
    borderTop: "1px solid #BFBFC0"
  };

  //description buttons
  const EditButton = ({ to }) => (
    <StyledButton to={to} title="Edit">
      <i className="fas fa-pencil-alt" />
    </StyledButton>
  );

  const DeleteButton = ({ index, onDelete }) => (
    <StyledButton title="Delete" onClick={() => onDelete(index)}>
      <i className="fas fa-trash" />
    </StyledButton>
  );

  const TileButton = ({ index, active, onTile }) => {

    return (
      <StyledButton
        title="Show on Resio"
        className="tile-button"
        onClick={() => onTile(index, !active)}
        active={active}

      >

        <img src={active ? iconTileActive : iconTile} alt="Show on Resio" />
      </StyledButton>
    )

    // <StyledButton
    //   title="Show on Resio"
    //   className="tile-button"
    //   onClick={() => onTile(index, !active)}
    //   active={active}

    // >

    //   <img src={active ? iconTileActive : iconTile} alt="Show on Resio" />
    // </StyledButton>
  };

  const { items: softSkills, listDragEnd } = useFrameDnd(
    TileTypes.softSkill,
    selectors.getSoftSkills,
    resio,
    tiles,
    updateResio,
  );

  const filteredSoftSkills = softSkills.filter(skill => skill.id && skill.title);
  const onDelete = (index) => {

    const accept = () => {
      const updatedSoftSkills = resio.softSkills?.updatedSoftSkills || [];

      // Ensure the skill at the given index is removed
      const newSoftSkills = updatedSoftSkills.filter((_, i) => i !== index);

      // Check if resio is being updated properly
      const update = {
        ...resio,
        softSkills: {
          ...resio.softSkills,
          updatedSoftSkills: newSoftSkills,
        },
        tiles: tiles
          .filter((x) => !(x.type === TileTypes.softSkill && x.index === index)) // Remove the corresponding tile
          .map((x) => {
            if (x.type === TileTypes.softSkill && x.index > index) {
              return { ...x, index: x.index - 1 }; // Adjust indexes
            }
            return x;
          }),
      };


      // Dispatch the update
      updateResio(resio.id, update);
    };

    modals.confirm({ text: "Delete entry?", accept });
  };


  const onToggle = (index, checked) => {
    let updatedTiles = [...(resio.tiles || [])];

    // Find index of "softSkill" tile at index 0
    const softSkillIndex = updatedTiles.findIndex(
      (tile) => tile.type === "softSkill" && tile.index === 0
    );

    if (softSkillIndex !== -1) {
      // 🗑 Remove the "softSkill" tile at index 0
      updatedTiles.splice(softSkillIndex, 1);
    } else if (checked) {
      // ✅ Add "softSkill" tile at index 0 if toggled on
      updatedTiles.push({
        type: "softSkill",
        index: 0,
        id: uniqueId("tile_"),
      });
    }



    // 🚀 Dispatch the update with modified tiles
    updateResio(resio.id, {
      ...resio,
      tiles: updatedTiles,
    });
  };






  //   const onToggle = (index, checked) => {
  //     const update = { ...resio };
  //     console.log(checked, "checked");
  //     if (checked) {
  //       update.tiles = [
  //         ...update.tiles,
  //         {
  //           type: TileTypes.softSkill,
  //           index,
  //           id: uniqueId("tile_"),
  //         },
  //       ];
  //       console.log(update, "updateeeee");
  //     } else {
  //       update.tiles = update.tiles.filter(
  //         (x) => (x.type !== TileTypes.softSkill )

  //       );
  // console.log(update, "updateeeee");
  //     }
  //     update?.tiles.some(tile => {
  //       if (tile.type === TileTypes.softSkill) {
  //         setIsTileToggle(true);
  //         console.log("if");
  //       }
  //       else {
  //         console.log("else");

  //         setIsTileToggle(false);
  //       }
  //     })
  //     updateResio(resio.id, update);
  //     console.log("updateresio");
  //   };

  const onDeleteDescription = () => {
    const accept = () => {
      const update = {
        ...resio,
        softSkills: {
          ...resio.softSkills,
          updatedSoftSkills: [], // Empty all soft skills
          OverallDescription: [], // Empty the description
        },
        tiles: tiles.filter((x) => x.type !== TileTypes.softSkill), // Remove all soft skill tiles
      };

      updateResio(resio.id, update);
    };

    modals.confirm({ text: "Clear description and all skills?", accept });
  };
  // const toggleTile = ({ resio }) => {
  //   resio?.tiles.some(tile => {
  //     if (tile.type === TileTypes.softSkill) {
  //       setIsTileToggle(true);
  //     }
  //     else {

  //       setIsTileToggle(false);
  //     }
  //   })
  // }

  return (
    <>
      <GoBackButton onClick={() => history.goBack()} />
      <Frame
        header={contentTypes.softSkills.title}
        shadow
        noPadding
        noOverflow
        headerBorderColor={contentTypes.softSkills.color}
      >
        <Helmet>
          <title>{contentTypes.softSkills.title}</title>
        </Helmet>
        <SubHeader>
          <h3>
            Show employers the valuable interpersonal skills you bring to the
            workplace. Just click to add an entry and select the relevant skills
            from the list.
          </h3>
          <span>
            Drag and drop in order of preference of how they should be displayed
            on your Resio.
          </span>

        </SubHeader>

        <div style={containerNote}>
          <div style={dFlex}>
            <textarea
              placeholder="Add an overall description to convey the effectiveness of your interpersonal skills and how they would benefit your ideal employer - 400 characters maximum"
              value={overallDescription}
              readOnly
              maxLength={400}
              style={{
                width: "100%",
                minHeight: "100px",
                padding: "10px",
                fontSize: "14px",
                border: "1px solid lightgrey",
                borderRadius: "5px",
                padding: "10px",
                fontFamily: 'mulish',
                marginRight: "30px"
              }}
            />

            <span style={dFlex}>
              <TileButton
                index={0}
                active={resio.tiles.some(tile => tile.type === TileTypes.softSkill && tile.isEnabled)}
                onTile={onToggle}
              />

              <EditButton to={`${match.url}/add`} />

              <DeleteButton onDelete={onDeleteDescription} />

            </span>

          </div></div>
          <FrameList
  addButton={<FrameListAdd to={`${match.url}/add`} />}
  listDragEnd={listDragEnd}
>
  {filteredSoftSkills.map(({ title, items = [], id, isEnabled }, index) => {
    const skill = resio?.softSkills?.updatedSoftSkills?.find(s => s.id === id) || {};
    const softSkillDescription = skill.softSkillDescription;
    
    return (
      <FrameListItem
        key={index}
        title={title}
        subtitle={
          <>
            {items.map((i) => i.title).join(", ")}
            
            {/* Display softSkillDescription with word wrapping */}
            {softSkillDescription ? (
              <div
                style={{
                  marginTop: "5px",
                  fontSize: "14px",
                  color: "#434a54",
                  whiteSpace: "pre-line",
                  wordBreak: "break-word",
                  maxWidth: "100%", // Ensures it wraps properly
                }}
              >
                {softSkillDescription}
              </div>
            ) : (
              <div style={{ marginTop: "5px" }}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(`${match.url}/${index}`);
                  }}
                  style={{
                    color: "#434a54",
                    textDecoration: "underline",
                    fontSize: "14px",
                    cursor: "pointer",
                    display: "block",
                    marginTop: "3px",
                  }}
                >
                  Click here to add description
                </a>
              </div>
            )}
          </>
        }
        onDelete={() => onDelete(index)}
        id={id}
        index={index}
        editUrl={`${match.url}/${index}`}
        active={isEnabled}
      />
    );
  })}
</FrameList>

      </Frame>
    </>
  );
};

export default SoftSkills;