import React, { useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import has from "lodash/has";
import { media } from "../../styles";

import { selectors as resioSelectors } from "../../redux/modules/resio";
import { selectors as resiosSelectors } from "../../redux/modules/resios";

import {
  requestReferenceRequests,
  publishReference
} from "../../redux/modules/referenceRequests/referenceRequestsActions";
import { getReferenceRequests } from "../../redux/modules/referenceRequests/referenceRequestsSelectors";

import contentTypes from "../../constants/contentTypes";
import ViewPageBanner from "../../components/ViewPageBanner";
import Container from "../../components/Container";
import SectionCard from "../../containers/SectionCard";
import icons from "../../static/icons";

import { requestResio } from "../../redux/modules/resio";

import ReferenceInfo from "./components/ReferenceInfo";
import ReferenceContent from "./components/ReferenceContent";

const ReferencesView = ({ className, modal, id }) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const base = match.path.startsWith("/agency") ? "/agency/" : "/";

  const resio = useSelector(resioSelectors.getResio);
  const resios = useSelector(resiosSelectors.getResios);
  const references = useSelector(resioSelectors.getReferences);
  const workSkills = useSelector(resioSelectors.getWorkSkills);
  const softSkills = useSelector(resioSelectors.getSoftSkills);
  const referenceRequests = useSelector(getReferenceRequests);

  const isRequestView = has(match.params, "id");
  const isEmailReferenceView = !!match.params.referenceId;
  const isUserResio = resios.some(item => item.id === resio.id);

  const { resioId, referenceId, id: _referenceId } = match.params;

  useEffect(() => {
    !modal && resioId && dispatch(requestResio(resioId));
  }, [modal, resioId]);

  const returnToUrl =
    isRequestView || isEmailReferenceView
      ? `${base}edit/${resio.id}/references`
      : `${base}${resio.id}`;

  const getDateByReference = (reference, isRequested) => {
    if (isRequested) {
      return `requested ${moment(reference.requestedDate).format(
        "Do MMM YYYY"
      )}`;
    }

    if (reference.reference) {
     
      return `received ${moment(reference.receivedDate).format("Do MMM YYYY")}`;
    }

    return `written ${moment(reference.receivedDate).format("Do MMM YYYY")}`;
  };

  const onPublish = reference => {
    const index = references.indexOf(reference);
    dispatch(publishReference(index, null, null, true));
  };

  const filteredReferences = !isRequestView
    ? references.filter((reference, index) => {
        const isIndex = !isNaN(Number(referenceId));
        if (modal) {
          return reference.id === id;
        }
        if (
          referenceId === undefined ||
          (isIndex && !references[referenceId])
        ) {
          return reference.published !== false;
        } else {
          return isIndex
            ? index === Number(referenceId)
            : reference.id === referenceId;
        }
      })
    : [];

  const filteredReferenceRequests = isRequestView
    ? referenceRequests.filter(
        reference =>
          reference.id === referenceId || reference.id === _referenceId
      )
    : [];

  const title = isRequestView
    ? contentTypes.references.titleAliases[0]
    : contentTypes.references.title;

  useEffect(() => {
    if (isUserResio && isRequestView) {
      dispatch(requestReferenceRequests(resio.id));
    }
  }, [dispatch, resio.id, isUserResio, isRequestView]);

  if (!references.length && !referenceRequests.length) {
    return null;
  }

  return (
    <div className={className}>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {!modal && <ViewPageBanner title={title} returnTo={returnToUrl} />}

      <Container>
        {filteredReferences.map((reference, index) => (
          <SectionCard
            key={index}
            className={classNames("reference-section", {
              "email-request": isEmailReferenceView || isRequestView
            })}
            minHeight={330}
          >
            <ReferenceInfo
              className="reference-section__info"
              reference={reference}
              resio={resio}
              date={getDateByReference(reference, false)}
            />

            <ReferenceContent
              className="reference-section__content"
              workSkills={workSkills}
              softSkills={softSkills}
              reference={reference}
              heading={
                reference.reference
                  ? `${reference.firstName} ${reference.lastName} said`
                  : "Reference Transcript"
              }
              letter={reference.reference || reference.transcription}
            />

            {isEmailReferenceView ? (
              <div
                className="reference-section__status reference-section__with-text"
                onClick={() => onPublish(reference)}
              >
                <img
                  src={
                    reference.published
                      ? icons.iconReferencePublished
                      : icons.iconReferenceUnpublished
                  }
                  alt={reference.published ? "Published" : "Unpublished"}
                />
                <span className="reference-section__text">
                  {reference.published ? "Unpublish" : "Publish"}
                </span>
              </div>
            ) : null}
          </SectionCard>
        ))}

        {filteredReferenceRequests.map((reference, key) => (
          <SectionCard
            key={key}
            className="reference-section email-request"
            minHeight={330}
          >
            <ReferenceInfo
              date={getDateByReference(reference, true)}
              className="reference-section__info"
              reference={reference}
              resio={resio}
            />

            <ReferenceContent
              className="reference-section__content"
              workSkills={workSkills}
              softSkills={softSkills}
              reference={reference}
              heading="You said"
              letter={reference.message}
            />
            <div className="reference-section__status">
              <img src={icons.iconReferencePending} alt="Pending" />
            </div>
          </SectionCard>
        ))}
      </Container>
    </div>
  );
};

export default styled(ReferencesView)`
  .reference-section {
    display: flex;
    color: #434a54;
    position: relative;
    min-height: auto;
    flex-direction: column;

    padding: 12px 18px;
    margin-left: -8px;
    margin-right: -8px;
    margin-top: 6px;

    .reference-section__content {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
    }

    &.email-request {
      .reference-section__content {
        margin-bottom: 75px;
      }
    }

    &__with-text {
      display: flex;
      align-items: center;

      & .reference-section__text {
        margin-left: 10px;
      }
    }

    ${media.tablet`
      flex-direction: row;
      margin-left: -10px;
      margin-right: -10px;
      margin-top: 20px;
      padding: 28px;
      
      .reference-section__content {
        margin-top: 0;
      }
            
      &.email-request {
        .reference-section__info {
          margin-bottom: 50px;
        }
        
        .reference-section__content {
          margin-bottom: 0;
        }
      }
      
      .reference-section__status {
        bottom: 28px;
        left: 30px;
      }
    `}

    &__info {
      min-width: 290px;
    }

    &__content {
      width: 100%;
    }

    &__status {
      position: absolute;
      bottom: 12px;
      left: 18px;
      cursor: pointer;

      &:hover {
        img {
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.7);
        }
      }

      img {
        width: 40px;
        height: 40px;
        cursor: pointer;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        border-radius: 100%;
      }

      span {
        margin-left: 6px;
        font-size: 14px;
        color: #656d78;
        text-decoration: underline;
      }
    }
  }
`;
