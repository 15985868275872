import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Button from "../../components/Button";

const FormButtons = ({
  className,
  submitDisabled,
  submitLabel,
  previewLabel,
  cancelUrl,
  onCancel,
  onSubmit,
  onPreview,
  loading,
  previewLoading,
  leftSide,
  loadingWithoutText
}) => (
  <div className={className}>
    {leftSide}

    <div className="form-buttons__buttons">
      {onCancel ? (
        <Button element={"a"} onClick={onCancel} secondary>
          Cancel
        </Button>
      ) : (
        cancelUrl && (
          <Button element={Link} to={cancelUrl} secondary>
            Cancel
          </Button>
        )
      )}
      {onPreview && (
        <Button
          onClick={event => {
            event.preventDefault();
            onPreview();
          }}
          primary
          disabled={previewLoading || submitDisabled}
          loading={!loadingWithoutText && previewLoading}
        >
          {previewLabel || "Preview"}
        </Button>
      )}
      <Button
        onClick={onSubmit}
        type="submit"
        primary
        disabled={loading || submitDisabled}
        noRightMargin
        loading={!loadingWithoutText && loading}
      >
        {submitLabel || "Save"}
      </Button>
    </div>
  </div>
);

export default styled(FormButtons)`
  display: flex;

  .form-buttons__buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 8px;
  }

  ${props =>
    !props.noMargins &&
    `
    width: calc(100% + 4rem);
    margin: 0 -2rem -1.5rem;
  `};

  ${props => !props.noBackground && `background: #434a54;`}
  padding: 14px 2rem;

  button,
  a {
    font-weight: 500;
    margin-bottom: 0;
  }
`;
