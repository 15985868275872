import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Dotdotdot from "react-dotdotdot";
import contentTypes from "../../constants/contentTypes";
import baseTile from "./baseTile";
import TileHeader from "../../components/TileHeader";
import TileContent from "../TileContent";

const TileIdealOpportunity = ({ className, values, baseUrl }) => {
  const url = baseUrl.includes("/edit")
    ? `${baseUrl}/ideal-opportunity`
    : `${baseUrl}/about-me`;

  return (
    <Link
      to={({ pathname }) => {
        if (pathname.includes("access")) {
          return `${pathname.replace("/access", "")}/about-me`;
        }
        return url;
      }}
      className={className}
    >
      <TileHeader subheading="My ideal employer:">
        {contentTypes.aboutMe.title}
      </TileHeader>
      <div>
        {Array.from(values)
          .slice(0, 3)
          .map((value, i) => (
            <TileContent listItem key={value} lines={1}>
              {value}
            </TileContent>
          ))}
      </div>
    </Link>
  );
};

export default styled(baseTile(TileIdealOpportunity))`
  background: ${contentTypes.idealOpportunity.color};
  color: #fff;
  align-items: flex-start;
  justify-content: flex-start;
`;
