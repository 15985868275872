import styled from "styled-components";
import Button from "../../components/Button";

export const StyledDialog = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  margin: 20px auto;
  text-align: center;
  background-color: #fff;
`;

export const StyledDropzone = styled.div`
  margin: 20px 0;
  padding: 20px;
  border: ${(props) => (props.isDragActive ? '2px dashed #0f0' : '2px dashed #ccc')};
  border-radius: 4px;
  background-color: #f9f9f9;
`;

export const StyledButton = styled(Button)`
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

export const CancelStyledButton = styled(Button)`
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

export const StyledButtonDisabled = styled(Button)`
  background-color: #ccc;
  cursor: not-allowed;
`;

export const SubHeader = styled.p`
  width: 290px;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: -0.66px;

  color: #424a55;
`;