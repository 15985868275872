import React from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { updateResio, selectors } from "../../redux/modules/resio";
import { get } from "../../api/resio";
import isEqual from "lodash/isEqual";
import { v4 as uuidv4 } from "uuid";

import Frame from "../../components/Frame";
import FormButtons from "../../components/FormButtons";
import CreateField from "../../components/CreateField";
import { GoBackButton } from "../../components/GoBackButton";
import RouteLeavingGuard from "../../components/CustomPrompt";
import softSkills from "../../constants/softSkills";
import contentTypes from "../../constants/contentTypes";

class AddSoftSkill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dirt: false,
      error: null,
      description: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  setIsEditing = (value) => {
    this.setState({ dirt: value });
  };

  async onSubmit(data) {
    const { resio, updateResio, history } = this.props;
    const { id, softSkills = {} } = resio;

    this.setIsEditing(false);

    const previousSkills = softSkills.updatedSoftSkills || [];
    const selectedSkills = Array.isArray(data.skills) ? data.skills : [];
    const softSkillsDescription = this.state.description.trim();

    const newSkills = selectedSkills.map((skill) => ({
      title: skill.value,
      id: uuidv4(),
    }));

    let updatedSoftSkills = previousSkills.filter((skill) =>
      selectedSkills.some((selected) => selected.value === skill.title)
    );

    newSkills.forEach((skill) => {
      if (!updatedSoftSkills.some((existing) => existing.title === skill.title)) {
        updatedSoftSkills.push(skill);
      }
    });

    // Check total skills count
    if (updatedSoftSkills.length > 10) {
      this.setState({ error: "You can select up to 10 skills only!" });
      return;
    }

    this.setState({ error: null });

    const update = {
      ...resio,
      softSkills: {
        updatedSoftSkills,
        OverallDescription: [{ description: softSkillsDescription }],
      },
    };

    await updateResio(id, update);

    history.push(`/edit/${id}/soft-skills`);
  }

  componentDidMount() {
    const { resio, initialize, dispatch } = this.props;

    if (!resio?.id) return;

    get(resio.id)
      .then((data) => {
        if (!isEqual(data.softSkills, resio.softSkills)) {
          dispatch(updateResio(resio.id, data));
        }

        const updatedSkills = data.softSkills?.updatedSoftSkills || [];
        const previousDescription = data.softSkills?.OverallDescription?.[0]?.description || "";

        this.setState({ description: previousDescription });

        initialize({
          skills: updatedSkills.map((skill) => ({
            value: skill.title,
            label: skill.title,
          })),
          description: previousDescription,
        });
      })
      .catch((error) => {
        console.error("❌ Error Fetching Data:", error);
      });
  }

  render() {
    const { handleSubmit, resio, selectedItems, isPosting } = this.props;

    const maxSkills = 10;
    const currentSkillCount = resio.softSkills?.updatedSoftSkills?.length || 0;
    const isMaxReached = currentSkillCount >= maxSkills;

    const skillsFormatted = [
      {
        value: "",
        label: "Start typing or scroll to select a soft skillset",
        isDisabled: true,
      },
      ...softSkills.map((skillset) => ({
        label: skillset.title,
        options: skillset.items
          ? skillset.items.map((skill) => ({
              value: skill,
              label: skill,
              group: skillset.title,
              isDisabled: selectedItems.includes(skill) && isMaxReached,
            }))
          : [],
      })),
    ];

    return (
      <>
        <GoBackButton onClick={() => this.props.history.goBack()} />
        <Frame
          header={contentTypes.softSkills.title}
          shadow
          headerBorderColor={contentTypes.softSkills.color}
        >
          <Helmet>
            <title>{contentTypes.softSkills.title}</title>
          </Helmet>

          <RouteLeavingGuard when={!!this.state.dirt} shouldBlockNavigation={() => !!this.state.dirt} />

          <form
            onChange={() => this.setIsEditing(true)}
            onSubmit={handleSubmit(this.onSubmit)}
          >
            <Field
              name="skills"
              component={CreateField}
              label="Skills"
              options={skillsFormatted}
              isMulti
              isDisabled={isMaxReached}
              error={this.state.error}
              placeholder="Select up to 10 skills"
              onChange={(value) => {
              
                this.setState({ error: value.length > 10 ? "You can select up to 10 skills only!" : null });
                this.props.change("skills", value);
              }}
            />

            {this.state.error && <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>{this.state.error}</div>}

            <div style={styles.textAreaContainer}>
              <label style={{ width: "100%" }}>
                <span style={styles.labelText}>Description</span>
                <textarea
                  value={this.state.description}
                  onChange={(e) => this.setState({ description: e.target.value })}
                  placeholder="Add an overall description (max 400 characters)"
                  maxLength={400}
                  style={styles.textArea}
                />
              </label>
            </div>

            <FormButtons
              cancelUrl={`/edit/${resio.id}${contentTypes.softSkills.url}`}
              loading={isPosting}
            />
          </form>
        </Frame>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const resio = selectors.getResio(state) || {};
  const softSkills = resio?.softSkills || {};

  const updatedSkills = softSkills.updatedSoftSkills || [];
  const previousDescription = softSkills.OverallDescription?.[0]?.description || "";

  return {
    resio,
    selectedItems: updatedSkills.map((skill) => skill.title),
    initialValues: {
      skills: updatedSkills.map((skill) => ({
        value: skill.title,
        label: skill.title,
      })),
      description: previousDescription,
    },
    isPosting: selectors.getPosting(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateResio: (id, data) =>
    new Promise((res, rej) => {
      dispatch(updateResio(id, data, res, rej));
    }),
});

const styles = {
  textAreaContainer: { display: "flex", alignItems: "center", gap: "2px" },
  labelText: { display: "block", fontWeight: "400", fontSize: "1rem", marginBottom: "5px", color: "#a7a7a7" },
  textArea: { width: "100%", minHeight: "100px", padding: "10px", fontSize: "14px", border: "1px solid lightgrey", borderRadius: "5px" },
  
};

AddSoftSkill = reduxForm({ form: "addSoftSkill", enableReinitialize: true })(AddSoftSkill);
AddSoftSkill = withRouter(AddSoftSkill);
AddSoftSkill = connect(mapStateToProps, mapDispatchToProps)(AddSoftSkill);

export default AddSoftSkill;
