import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Muuri from "muuri";
import get from "lodash/get";
import find from "lodash/find";
import uniqueId from "lodash/uniqueId";
import { withRouter } from "react-router-dom";

import {
  updateResio,
  selectors,
  setIsEntityAddedFromBlankGrid
} from "../../redux/modules/resio";

import { constants } from "../../styles";
import contentTypes from "../../constants/contentTypes";
import TileTypes from "../../constants/tileTypes";
import TileContainer from "../../components/TileContainer";
import ContentTypeModal from "../../components/ContentTypeModal";
import Tiles from "../../components/Tiles";
import MediaCarousel from "../../components/MediaCarousel";

class TileGrid extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.refreshMuuri = this.refreshMuuri.bind(this);
    this.renderTile = this.renderTile.bind(this);
    this.getContentType = this.getContentType.bind(this);
    this.onHideCarousel = this.onHideCarousel.bind(this);

    const tiles = props?.tiles?.map(t => ({
      ...t,
      id: uniqueId("tile_") // we don't want to use index as stuff moves
    }));
    const isEmptyTileExist = tiles.find(tile => tile.type === null);

    if (!isEmptyTileExist) {
      tiles.push({ type: null });
    }

    this.state = {
      showModal: false,
      // theoretically this should never update whilst the component is mounted
      // because we don't render the routes whilst isFetching is true
      // therefore we don't have to worry about componentDidUpdate etc.
      tiles,
      activeSlide: null
    };
  }
  componentDidMount() {
    this.refreshMuuri();
    if (this.props.shouldAddToTileGrid === true) {
      this.props.addToGridAsDefault(false);
    }
  }

  onSubmit(newTiles) {
    const update = {
      ...this.props.resio,
      tiles: newTiles
    };

    this.props.updateResio(this.props.resio.id, update);
  }

  onOpenModal(activeTile) {
    if (activeTile.url) {
      const { history, resio } = this.props;

      history.push(`/edit/${resio.id}${activeTile.url}`);
      return;
    }

    this.setState(ps => ({
      ...ps,
      showModal: true,
      activeTile
    }));
  }

  onCloseModal() {
    this.setState(ps => ({
      ...ps,
      showModal: false,
      activeTile: null
    }));
  }

  onShowCarousel(media) {
    this.setState({ activeSlide: media });
  }

  onHideCarousel() {
    this.setState({ activeSlide: null });
  }

  refreshMuuri() {
    const { readonly } = this.props;
    if (!this.domList) return;
    if (this.muuri) this.muuri.destroy();

    this.muuri = new Muuri(this.domList, {
      layoutDuration: 400,
      layoutEasing: "ease",
      dragEnabled: !readonly, // this makes it readonly
      dragStartPredicate: (item, event) => {
        if (this.muuri.getItems().indexOf(item) === 0) {
          return false;
        }
        return Muuri.ItemDrag.defaultStartPredicate(item, event, {
          distance: 30
        });
      },
      dragSortPredicate: item => {
        var result = Muuri.ItemDrag.defaultSortPredicate(item);
        return result && result.index === 0 ? false : result;
      },
      dragSort: true,
      dragSortInterval: 0,
      dragContainer: document.body,
      dragReleaseDuration: 400,
      dragReleaseEasing: "ease",
      layout: {
        fillGaps: true
      }
    });

    this.muuri.on("dragEnd", () => {
      const orderedIds = this.muuri
        .getItems()
        .map(item => item.getElement().getAttribute("data-id"));

      const newTiles = orderedIds
        .filter(id => id !== null && id !== "profile-picture")
        .map(id => find(this.state.tiles, ["id", id]));

      this.onSubmit(newTiles);
    });
  }

  getContentType(type) {
    return (
      contentTypes[type] ||
      Object.values(contentTypes).find(contentType =>
        contentType.keyAliases.includes(type)
      )
    );
  }

  renderTile(tile, resio) {
    const { readonly, agency, addToGridAsDefault } = this.props;
    const contentType = this.getContentType(tile.type);
    let wide = false;
    let Render = readonly ? undefined : Tiles.Blank;

    let args = {
      onClick: activeTile => {
        this.onOpenModal(activeTile);
        const url = activeTile.url;
        switch (url) {
          case "/work-history":
            addToGridAsDefault(true);
            break;
          case "/education":
            addToGridAsDefault(true);
            break;
          case "/awards":
            addToGridAsDefault(true);
            break;

          default:
            break;
        }
      }
    };
    let isBlank = false;

    let baseUrl = "";

    if (agency) baseUrl = "/agency/";
    else if (!readonly) baseUrl = "/edit/";

    baseUrl += resio.id;

    switch (tile.type) {
      case TileTypes.desiredRole: {
        wide = true;
        const props = {
          desiredJobRole: get(resio, "desiredJobRole"),
          desiredIndustry: get(resio, "desiredIndustry"),
          locations: get(resio, "location"),
          travelDistance: get(resio, "travelDistance"),
          // willRelocate: get(resio, "willRelocate")
        };

        if (
          Object.values(props).every(
            prop => prop === undefined || prop === null
          )
        ) {
          isBlank = true;
          break;
        }
        Render = Tiles.DesiredRole;
        args = {
          ...props,
          baseUrl
        };
        break;
      }
      case TileTypes.aboutMe: {
        wide = true;
        const name = get(resio, "firstName");
        if (!name || name.length <= 0) break;
        const lastName = get(resio, "lastName");
        const profession = get(resio, "profession");
        const company = get(resio, "company");
        const coreIndustry = get(resio, "coreIndustry");
        const location = get(resio, "currentLocation");
        Render = Tiles.AboutMe;

        args = {
          fullName: name + " " + (lastName || ""),
          profession,
          company,
          coreIndustry,
          location,
          baseUrl: ""
        };
        break;
      }
      case TileTypes.description: {
        wide = true;
        const value = get(resio, "description");
        if (!value || value.length <= 0) {
          isBlank = true;
          break;
        }
        Render = Tiles.Description;
        args = {
          value,
          baseUrl
        };
        break;
      }
      case TileTypes.idealOpportunity: {
        wide = true;
        const values = get(resio, "idealOpportunities");
        if (values === null || values === undefined) {
          isBlank = true;
          break;
        }
        Render = Tiles.IdealOpportunity;
        args = {
          values,
          readonly,
          baseUrl
        };
        break;
      }
      case TileTypes.contact: {
        wide = true;
        const email = get(resio, "email");
        if (!email || email.length <= 0) {
          isBlank = true;
          break;
        }
        const phone = get(resio, "phone");
        const video_chat_url = get(resio, "video_chat_url");
        const video_chat_name = get(resio, "video_chat_name");
        Render = Tiles.Contact;
        args = {
          email,
          phone,
          video_chat_url,
          video_chat_name,
          baseUrl,
          readonly
        };
        break;
      }
      case TileTypes.experience: {
        wide = true;
        const { index = 0 } = tile;
        const experience = get(resio, `experience[${index}]`);
        if (experience === null || experience === undefined) {
          isBlank = true;
          break;
        }
        Render = Tiles.Experience;
        args = {
          title: experience.title,
          company: experience.company,
          location: experience.location,
          startYear: experience.startYear,
          finishYear: experience.finishYear,
          baseUrl
        };
        break;
      }
      case TileTypes.award: {
        const { index = 0 } = tile;
        const award = get(resio, `awards[${index}]`);
        const isNoAward = award === null || award === undefined;
        wide = isNoAward ? false : true;

        if (isNoAward) {
          isBlank = true;
          break;
        }
        Render = Tiles.Award;
        args = {
          title: award.title,
          issuer: award.issuer,
          year: award.issueYear,
          baseUrl
        };
        break;
      }
      case TileTypes.education: {
        wide = true;
        const { index = 0 } = tile;
        const education = get(resio, `education[${index}]`);

        if (education === null || education === undefined) {
          isBlank = true;
          break;
        }
        Render = Tiles.Education;
        args = {
          education,
          baseUrl
        };
        break;
      }
      case TileTypes.softSkill: {
        const softSkills = get(resio, `softSkills.updatedSoftSkills`, []); // Ensure it's an array
        const overallDescription = get(resio, `softSkills.OverallDescription[0].description`, "Interpersonal Skills")
        const isNoSoftSkill = !softSkills || softSkills.length === 0;
        wide = !isNoSoftSkill;

        if (isNoSoftSkill) {
          isBlank = true;
          break;
        }

        Render = Tiles?.SoftSkill;
        args = {
          // title: overallDescription,
          values: softSkills.map(skill => skill.title), // ✅ Fetch all soft skills' titles
          baseUrl
        };
        break;
      }



      case TileTypes.workSkill: {
        const { index = 0 } = tile;
        const workSkill = get(resio, `workSkills[${index}]`);
        const isNoWorkSkill = workSkill === null || workSkill === undefined;
        wide = isNoWorkSkill ? false : true;
        if (isNoWorkSkill) {
          isBlank = true;
          break;
        }

        Render = Tiles.WorkSkill;
        args = {
          title: workSkill.title,
          values: workSkill.items.slice(0, 3).map(i => i.title),
          baseUrl
        };
        break;
      }
      case TileTypes.reference: {
        wide = true;
        const { index = 0 } = tile;
        const reference = get(resio, `references[${index}]`);

        if (
          reference === null ||
          reference === undefined ||
          (reference && !reference.published)
        ) {
          isBlank = true;
          break;
        }
        Render = Tiles.Reference;
        args = {
          referenceId: reference.id,
          name: `${reference.firstName} ${reference.lastName}`,
          jobTitle: reference.jobTitle,
          company: reference.organisation,
          skillset: (reference.skillset || []).map(
            skill => skill.title || skill
          ),
          baseUrl
        };
        break;
      }
      case TileTypes.media: {
        wide = false;
        const { index = 0 } = tile;
        const media = get(resio, `media[${index}]`);
        if (media === null || media === undefined) {
          isBlank = true;
          break;
        }
        switch (media.type) {
          case "image": {
            Render = Tiles.Image;
            args = {
              name: media.name,
              src: media.url,
              baseUrl,
              readonly,
              onClick: () => {
                if (!media.hideOnCarousel) {
                  this.onShowCarousel(media, index);
                }
              }
            };
            break;
          }

          case "document": {
            Render = Tiles.MediaLink;
            args = {
              name: media.name,
              url: media.url,
              baseUrl,
              readonly
            };
            break;
          }

          case "link": {
            const isVideo =
              media.origin === "youtube" || media.origin === "vimeo";
            wide = isVideo;
            Render = isVideo ? Tiles.ExternalVideo : Tiles.MediaLink;
            args = {
              name: media.name,
              url: media.url,
              origin: media.origin,
              baseUrl,
              readonly,
              onClick: () => {
                if (isVideo && !media.hideOnCarousel) {
                  this.onShowCarousel(media);
                }
              }
            };
            break;
          }

          default:
            break;
        }

        break;
      }
      default:
        break;
    }

    if (Render === undefined) {
      return;
    }

    if (isBlank) {
      const { tiles } = this.state;
      const sameCategoryTiles = tiles.filter(item => item.type === tile.type);
      const isDuplicate = sameCategoryTiles[0] !== tile;
      const isDeprecated = tile.type !== null && !contentType;

      if (isDuplicate || isDeprecated) {
        return;
      }
    }

    return (
      <TileContainer wide={wide} id={tile.id} key={tile.id}>
        <Render {...args} contentType={contentType} />
      </TileContainer>
    );
  }

  renderProfilePicture() {
    const { readonly, agency, resio } = this.props;
    const profilePicture = get(resio, "profilePicture");

    let baseUrl = "";

    if (agency) baseUrl = "/agency/";
    else if (!readonly) baseUrl = "/edit/";

    baseUrl += resio.id;

    return (
      <TileContainer>
        <Tiles.ProfilePicture
          src={profilePicture}
          readonly={readonly}
          baseUrl={baseUrl}
        />
      </TileContainer>
    );
  }

  render() {
    const { className, resio, isFetching, readonly } = this.props;
    const { showModal, tiles, activeSlide, activeTile } = this.state;

    const isEmpty =
      !tiles.length || (tiles.length === 1 && tiles[0].type === null);
    return (
      <div>
        {activeSlide && (
          <MediaCarousel
            hideable
            list={[...resio.media].reverse()}
            activeItem={activeSlide}
            onClose={this.onHideCarousel}
          />
        )}

        <div
          className={`${className}${readonly ? " preview" : ""}`}
          ref={l => {
            this.domList = l;
          }}
        >
          {!isFetching && this.renderProfilePicture()}
          {!isFetching && !isEmpty && tiles.map(a => this.renderTile(a, resio))}
        </div>
        <ContentTypeModal
          resioId={resio && resio.id}
          isOpen={showModal}
          onClose={this.onCloseModal}
          defaultTile={activeTile}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tiles: selectors.getAllTiles(state),
  resio: selectors.getResio(state),
  media: selectors.getMedia(state),
  isFetching: selectors.getFetching(state),
  shouldAddToTileGrid: state.resio.shouldAddToTileGrid
});

const mapDispatchToProps = dispatch => ({
  updateResio: (id, data) => {
    dispatch(updateResio(id, data, null, null, true));
  },
  addToGridAsDefault: (value = false) => {
    dispatch(
      setIsEntityAddedFromBlankGrid({
        payload: value
      })
    );
  }
});

TileGrid = styled(TileGrid)`
  position: relative;
  max-width: ${(constants.tileWidthWide + constants.tileMargin) * 3}px;
  ${props => props.centered && "margin: 0 auto;"};
`;

TileGrid = withRouter(TileGrid);

export default connect(mapStateToProps, mapDispatchToProps)(TileGrid);
