import React from "react";
import styled from "styled-components";
import Dotdotdot from "react-dotdotdot";

export default styled(
  ({ className, lines, children, id = null, bold, italic, listItem }) => {
    return lines ? (
      // <Dotdotdot
      //   className={`${className} ${listItem ? "list_item" : "flex"}
      //   ${bold ? "bold" : ""}
      //   ${italic ? "italic" : ""}
      // `}
      //   clamp={lines}
      //   tagName="p"
      // >
      //   {children}
      // </Dotdotdot>
      <ul className={`${className} custom-ul`}>
        <li
          className={`${className} custom-li ${listItem ? "list_item" : "flex"} ${bold ? "bold" : ""} ${italic ? "italic" : ""}`}
        >
          <span className="custom-elipses">{children}</span>

        </li>
      </ul>
    ) : (
      <div
        id={id}
        className={`
        ${className} ${listItem ? "list_item" : "flex"}
        ${bold ? "bold" : ""} 
        ${italic ? "italic" : ""}
        `}
      >
        {children}
      </div>
    );
  }
)`
  &.flex {
    display: flex;
  }

  &.bold {
    font-weight: 500;
  }

  &.italic {
    font-style: italic;
  }

  &.list_item {
    display: list-item !important;
    margin-left: 1.3em !important;
  }

  font-size: 16px;
  margin: 0;
  font-weight: 300;

  ${(props) => props.italic && `font-style: italic;`}
  ${(props) => props.bold && `font-weight: 400;`}
  margin-bottom: ${(props) => (props.marginBottom ? "0.3rem" : "0")};
  ${(props) => props.spaced && `line-height: 24px;`}
  ${(props) =>
    !props.lines &&
    `
      word-break: break-word;
      max-width: 100%;
      padding-right: 1px;
    `};
  &:hover {
    text-decoration: underline;
  }

  /* Styling for UL */
  &.custom-ul {
    padding-left: 0px !important;
    margin: 0px !important;
    margin: 10px 0;
    list-style-type: disc; /* You can change it to circle, square, etc. */
    text-overflow: ellipsis;

  }

  /* Styling for UL */
  &.custom-li {
    white-space: nowrap;
    width: 450px;
    overflow: hidden;
    text-overflow: ellipsis;
    list-style-position: inside;
    margin-left: 0em !important;
    min-width: 0
  }
    .custom-elipses{
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 60%;
    }
`;
