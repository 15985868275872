import React from "react";
import styled from "styled-components";

import { constants } from "../../styles";

const CloseButton = styled(({ className, hide }) => (
  <button type="button" className={className} onClick={hide} title="Hide">
    <i className="fas fa-times" />
  </button>
))`
  width: 2rem;
  flex-shrink: 0;
  color: inherit;
  outline: 0;
  border: 0;
  padding: 0;
  background: transparent;
  font-size: inherit;
  cursor: pointer;
  font-family: "Mulish", sans-serif;
`;

const VerifyEmailBanner = ({ className, onClose, onResend, resendStatus }) => {
  let message = (
    <React.Fragment>
      You haven't verified your email yet!{" "}
      <button type="button" onClick={onResend}>
        Resend email
      </button>
    </React.Fragment>
  );

  if (resendStatus) {
    message = <React.Fragment>{resendStatus}</React.Fragment>;
  }

  return (
    <div className={className}>
      {/* a spacer to center the text */}
      <div style={{ width: "2rem", flexShrink: 0 }} />
      <span>{message}</span>
      <CloseButton hide={onClose} />
    </div>
  );
};

export default styled(VerifyEmailBanner)`
  background: #ff7159;
  text-align: center;
  color: #fff;
  padding: 0 1rem;
  white-space: nowrap;
  display: flex;
  flex-flow: row;

  position: fixed;
  width: 100%;
  height: ${constants.verifyHeight};
  line-height: ${constants.verifyHeight};
  top: ${constants.bannerHeight};
  left: 0;
  right: 0;
  z-index: 305;

  @media print {
    display: none;
  }

  > span {
    flex: 1;
    margin-right: 1rem;
    overflow-x: auto;

    > button {
      color: inherit;
      outline: 0;
      border: 0;
      padding: 0;
      display: inline-block;
      background: transparent;
      font-size: inherit;
      text-decoration: underline;
      cursor: pointer;
      font-family: "Mulish", sans-serif;
    }
  }
`;
