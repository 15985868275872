import React from "react";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { withStyles } from "@material-ui/core";

export const ToggleButtons = ({
  items,
  value,
  onChange,
  customBgColor = "#02C099",
  customTextColor = "#fff"
}) => {
  const styles = () => ({
    group: {
      borderRadius: "28px"
    },
    button: {
      width: "112px",
      height: "28px",
      background: "#FFFFFF",
      color: "#434A54",
      textTransform: "none",
      fontFamily: '"Mulish", sans-serif',
      fontSize: "18px",
      fontWeight: "normal",

      "&:hover": {
        background: "#FFFFFF"
      },

      "&$selected": {
        background: customBgColor,
        fontWeight: 500,
        color: customTextColor,
        boxShadow:
          "0 1px 2px rgba(0, 0, 0, 0.5), inset 0 0 4px rgba(0, 0, 0, 0.5)",

        "&:hover": {
          background: customBgColor
        }
      }
    },
    selected: {
      // Don't delete! Material UI requires empty selected object here,
    }
  });

  const Component = withStyles(styles)(({ classes }) => {
    return (
      <ToggleButtonGroup
        value={value}
        classes={{ root: classes.group }}
        onChange={(event, value) => onChange(value)}
        exclusive
      >
        {items.map(item => (
          <ToggleButton
            key={item}
            value={item}
            classes={{
              root: classes.button,
              selected: classes.selected
            }}
          >
            {item}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    );
  });

  return <Component />;
};
