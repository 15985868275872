import React, { Fragment } from "react";
import { string, array } from "prop-types";
import styled from "styled-components";
import { GridStyled, CellStyled, TitleStyled, ListItemStyled } from "./styles";

const propTypes = {
  skills: array,
  accentColor: string
};

const defaultProps = {
  skills: [],
  accentColor: "#000"
};

// BulletContainer and Bullet styled components
const BulletContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media print {
    display: flex;
    page-break-inside: avoid;
    page-break-after: avoid;
    page-break-before: avoid;
    min-width: 260px;
  }
`;

const Bullet = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 10px;
  margin-right: 10px;

  span {
    width: 7px;
    height: 7px;
    background-color: #c867cc;
  }

  @media print {
    page-break-inside: avoid;
    page-break-after: avoid;
    page-break-before: avoid;
    float: left;
  }
`;

const PrintViewSkills = ({ skills, accentColor }) => {
  // Log the entire skills array

  return (
    <GridStyled>
      {skills.map((skill, skillsIdx) => {
        const nextIdx = skillsIdx + 1;
        const prevIdx = skillsIdx - 1;

        // Check if skill is an object or an array
        const items = Array.isArray(skill.items) ? skill.items : (skill.items ? [skill.items] : []);

        const isIndependentGrid =
          items.length >= 3 ||
          (skills[nextIdx] &&
            Array.isArray(skills[nextIdx].items) &&
            skills[nextIdx].items.length + items.length > 3 &&
            prevIdx !== -1 &&
            Array.isArray(skills[prevIdx].items) &&
            skills[prevIdx].items.length + items.length > 3);

        const forceEnd =
          prevIdx !== -1 &&
          skills[nextIdx] &&
          Array.isArray(skills[nextIdx].items) &&
          skills[prevIdx].items.length === 1 &&
          skills[nextIdx].items.length > 1 &&
          items.length === 1 &&
          (!skills[skillsIdx - 2] || skills[skillsIdx - 2].items.length !== 1);

        const WrapperComponent = isIndependentGrid ? GridStyled : Fragment;

        const independentSetStyle = isIndependentGrid
          ? { gridColumn: "1 / -1" }
          : {};
        const endCellStyle = forceEnd ? { gridColumn: "2 / -1" } : {};

        return (
          <WrapperComponent
            key={skillsIdx}
            style={{
              marginBottom: items.length > 2 ? 7 : 0,
              ...independentSetStyle
            }}
          >
            {items.map((item, itemsIdx) => {
             
              return (
                <CellStyled
                  key={itemsIdx}
                  style={{
                    marginBottom: items.length < 3 ? 7 : 0,
                    ...endCellStyle
                  }}
                >
                  <TitleStyled>{itemsIdx === 0 ? skill.title : ""}</TitleStyled>
                  <BulletContainer>
                    <Bullet>
                      <span style={{ backgroundColor: accentColor,  }}></span>
                    </Bullet>
                    <ListItemStyled accentColor={accentColor}>
                      {item.title}
                    </ListItemStyled>
                  </BulletContainer>
                </CellStyled>
              );
            })}
          </WrapperComponent>
        );
      })}
    </GridStyled>
  );
};

PrintViewSkills.propTypes = propTypes;
PrintViewSkills.defaultProps = defaultProps;

export default PrintViewSkills;
