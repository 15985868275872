import { put, call, takeLatest, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";

import errorHandlers from "../../services/errorHandlers";
import modals from "../../services/modals";
import { resio } from "../../api";

const CLEAR_RESIOS_STATE = "resio/CLEAR_RESIOS_STATE";

const REQUEST_RESIOS = "resio/REQUEST_RESIOS";
const REQUEST_RESIOS_SUCCESS = "resio/REQUEST_RESIOS_SUCCESS";
const REQUEST_RESIOS_FAIL = "resio/REQUEST_RESIOS_FAIL";

const CREATE_RESIO = "resio/CREATE_RESIO";
const CREATE_RESIO_SUCCESS = "resio/CREATE_RESIO_SUCCESS";
const CREATE_RESIO_FAIL = "resio/CREATE_RESIO_FAIL";

const FILL_RESIO = "resio/FILL_RESIO";

const UPDATE_RESIOS = "resio/UPDATE_RESIOS";

const initialState = {
  resios: [],
  isFetching: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CLEAR_RESIOS_STATE:
      return initialState;
    case REQUEST_RESIOS:
      return {
        ...state,
        isFetching: true
      };
    case REQUEST_RESIOS_SUCCESS:
      return {
        ...state,
        resios: action.resios,
        isFetching: false
      };
    case REQUEST_RESIOS_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case CREATE_RESIO:
      return {
        ...state,
        isFetching: true
      };
    case FILL_RESIO:
      console.log("Jirka dispatcher called")
      return {
        ...state,
        isFetching: true
      }
    case CREATE_RESIO_SUCCESS:
      return {
        ...state,
        resios: state.resios.concat([action.newData]),
        isFetching: false
      };
    case CREATE_RESIO_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case UPDATE_RESIOS:
      return {
        ...state,
        resios: action.data
      };
    default:
      return state;
  }
}

export function clearResiosState() {
  return {
    type: CLEAR_RESIOS_STATE
  };
}

export function requestResios(navigatingToResio) {
  return {
    type: REQUEST_RESIOS,
    navigatingToResio
  };
}

function requestResiosSuccess(resios) {
  return {
    type: REQUEST_RESIOS_SUCCESS,
    resios
  };
}

function requestResiosFail(error) {
  return {
    type: REQUEST_RESIOS_FAIL,
    error
  };
}

export function* fetchResios(action) {
  try {
    const data = yield call(resio.getMine);
    yield put(requestResiosSuccess(data));
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(requestResiosFail(error));
    yield call(errorHandlers.showDialog);
  }
}

export function* requestResiosSaga() {
  yield takeLatest(REQUEST_RESIOS, fetchResios);
}

export function createResio(data) {
  return {
    type: CREATE_RESIO,
    data
  };
}

export function fillResio(data){
  console.log("Jirka fill resio called");
  return {
    type: FILL_RESIO,
    data
  }
}

export function createResioSuccess(newData) {
  return {
    type: CREATE_RESIO_SUCCESS,
    newData
  };
}

export function createResioFail(error) {
  return {
    type: CREATE_RESIO_FAIL,
    error
  };
}

function* postResio(action) {
  try {
    console.log('Jirka before post resio: ', action.data);
    const inserted = yield call(resio.create, action.data);
    console.log('Jirka gpt: ', inserted);
    yield put(createResioSuccess(inserted));
    window.mixpanel.people.increment("resios created");
    window.mixpanel.track("User created a new resio", {
      resio_title: inserted.title
    });

    yield put(push("/edit/" + inserted.id));
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(createResioFail(error));
    yield call(errorHandlers.showDialog);
  }
}

function* postFillResio(action){
  console.log("Jirka post fill called");

  try {
    const filled = yield call(resio.fill, action.data);
    yield put(createResioSuccess(filled));
    window.mixpanel.people.increment("resios created by filling from CV");
    window.mixpanel.track("User created a new resio by filling from CV", {
      resio_title: filled.title
    });

    yield put(push("/edit/" + filled.id));
  } catch(error){
    yield call(errorHandlers.report, error);
    yield put(createResioFail(error));
    yield call(errorHandlers.showDialog);
  }
}

export function* createResioSaga() {
  yield takeLatest(CREATE_RESIO, postResio);
}

export function* fillResioSaga() {
  yield takeLatest(FILL_RESIO, postFillResio);
}

export function updateResios(id, data, resolve, reject) {
  return {
    type: UPDATE_RESIOS,
    id,
    data,
    resolve,
    reject
  };
}

// selectors
export const selectors = {
  getResios: state => state.resios.resios,
  isFetching: state => state.resios.isFetching,
  hasResios: state => state.resios.resios.length > 0
};
