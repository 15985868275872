import styled from "styled-components";
import { Field } from "redux-form";
import Button from "../../components/Button";

export const StyledField = styled(Field)`
  width: 340px;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.54px;
  mix-blend-mode: normal;
`;

export const StyledButton = styled(Button)`
  width: 340px;
  height: 39px;
  backgroundcolor: #02c099;
  mix-blend-mode: normal;
  border-radius: 4px;
  padding: 0;
  font-size: 17px;
`;
