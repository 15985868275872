import React from "react";
import styled from "styled-components";
import { Link, Route, Switch, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

import { selectors } from "../../redux/modules/auth";

import { constants, media } from "../../styles";
import Button from "../../components/Button";
import logo from "../../static/logo.png";
import logo_beta from "../../static/logo_beta_white.png";
import BannerLoggedIn from "../../components/BannerLoggedIn";

const BannerLogo = ({ className, to }) => (
  <Link to={to} className={className}>
    <img src={logo_beta} alt="logo" />
  </Link>
);

const StyledBannerLogo = styled(BannerLogo)`
  outline: 0;
  width: 100%;
  max-width: 200px;
  min-width: 75px;

  display: none;
  ${media.tablet`display: initial;`};
  ${media.tablet`margin-right: 0.5rem;`};
  ${media.desktop`margin-right: 1.5rem;`};

  > img {
    width: 160px;
    height: 40px;
  }
`;

const LoggedOutView = ({ className }) => (
  <div className={className}>
    <Button outline element={Link} to="/recruiter-signup" noBottomMargin>
      Recruiting?
    </Button>
    <Button primary element={Link} to="/signup" noBottomMargin>
      Sign Up
    </Button>
    <Button element={Link} to="/login" noBottomMargin>
      Log In
    </Button>
  </div>
);

const StyledLoggedOutView = styled(LoggedOutView)`
  margin-left: auto;
  white-space: nowrap;
`;

let Banner = ({ className }) => {
  const isAuthenticated = useSelector(selectors.isAuthenticated);

  return (
    <header className={className}>
      <Switch>
        <Route
          path="/edit/:resioId([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})"
          render={({ match }) => (
            <StyledBannerLogo to={`/edit/${match.params.resioId}`} />
          )}
        />
        <Route render={({ match }) => <StyledBannerLogo to="/" />} />
      </Switch>

      {!isAuthenticated && <StyledLoggedOutView />}
      {isAuthenticated && <BannerLoggedIn />}
    </header>
  );
};

Banner = styled(Banner)`
  background: #434a54;
  color: #fff;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;

  padding: 0.2rem 0.5rem;
  ${media.tablet`padding: 0.3rem 0.7rem;`};
  ${media.desktop`padding: 1rem 1.5rem;`};

  position: fixed;
  height: ${constants.bannerHeight};
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 300;

  @media print {
    display: none !important;
  }
`;

export default withRouter(Banner);
