import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";
import { media } from "../../styles";

import {
  createAccess,
  requestAccessEmailPreview
} from "../../redux/modules/access/accessActions";

import {
  getFetching,
  getEmailPreviewFetching
} from "../../redux/modules/access/accessSelectors";

import { selectors as resioSelectors } from "../../redux/modules/resio";

import modals from "../../services/modals";

import Modal from "../../components/Modal";
import Frame from "../../components/Frame";
import FormButtons from "../../components/FormButtons";
import TextField from "../../components/TextField";
import ToggleField from "../../components/ToggleField";
import CheckboxField from "../../components/CheckboxField";
import SelectField from "../../components/SelectField";
import TextEditor from "../../components/TextEditor";

import {
  email,
  maxLength2048,
  maxLength4096,
  minLength2,
  required
} from "../../services/formValidators";

import { normalizeSelectValue } from "../../services/fields";
import html2pdf from "html2pdf.js";

const StyledHeading = styled.p`
  margin-top: 0;
`;

const StyledTextEditor = styled(TextEditor)`
  > label {
    display: none;
  }

  .quill {
    padding-top: 0 !important;
  }

  .ql-editor {
    min-height: 460px;
  }

  ${media.tablet`
    .ql-editor {
      min-height: 270px;
    }
  `};
`;

const StyledFormButtons = styled(FormButtons)`
  flex-direction: column;
  padding-top: 0;
  font-weight: 400;
  .form-buttons__buttons {
    justify-content: center;

    button {
      padding-left: 1rem;
      padding-right: 1rem;
      font-weight: 400;
    }

    & > a {
      line-height: normal;
      padding-left: 1rem;
      padding-right: 1rem;
      font-weight: 400;
    }
  }

  ${media.tablet`
    flex-direction: row;
    padding-top: 14px;
    
    .form-buttons__buttons {
      button, & > a {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
  `};
`;

const StyledLeftSide = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  & > label {
    margin-right: 0;
  }

  & > span {
    margin-right: 0.5rem;
    white-space: nowrap;
    color: #fff;

    &.copy {
      margin-left: 1rem;
    }
  }

  ${media.desktop`
    flex-direction: row;
    justify-content: flex-start;
    
    & > span {
      margin-right: 1rem;
      
      &.copy {
        margin-left: 1.5rem;
      }
    }
  `};
`;

const InviteModal = ({
  isOpen,
  onClose,
  handleSubmit,
  form,
  pristine,
  invalid,
  initialize
}) => {
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [isPdfGenerating, setIsPdfGenerating] = useState(false);

  const formValues = useSelector(getFormValues(form));
  const accesses = useSelector(({ access }) => access.list);

  const resio = useSelector(resioSelectors.getResio);
  const isFetching = useSelector(getFetching);
  const isEmailPreviewFetching = useSelector(getEmailPreviewFetching);

  //Pdf attachment work

  const generatePdf = async () => {
    if (!resio.id) return;

    setIsPdfGenerating(true);

    try {
      console.log("⏳ Generating PDF...");
      const styledHTML = await fetchHTMLFromIframe(
        `https://resio.app/${resio.id}`
      );

      if (!styledHTML) {
        console.error("❌ Failed to generate styledHTML for CV From Resio");
        return;
      }

      // Delay to ensure all images are rendered properly
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const blob = await html2pdf()
        .from(styledHTML)
        .set({
          margin: [10, 10, 10, 10],
          filename: "cv.pdf",
          image: { type: "jpeg", quality: 1.0 },
          html2canvas: {
            scale: 2,
            dpi: 96,
            letterRendering: true,
            useCORS: true,
          },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        })
        .outputPdf("blob");

      setPdfBlob(blob);
      console.log("✅ PDF Generated!");
    } catch (error) {
      console.error("❌ Error generating PDF:", error);
    } finally {
      setIsPdfGenerating(false);
    }
  };

  const fetchHTMLFromIframe = (url) => {
    return new Promise((resolve, reject) => {
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = url;

      iframe.onload = () => {
        try {
          const iframeDocument =
            iframe.contentDocument || iframe.contentWindow.document;
          const baseUrl = new URL(url).origin;

          setTimeout(() => {
            const cvToggle = iframeDocument.querySelector(".cv-toggle");
            if (!cvToggle) return reject("❌ .cv-toggle NOT FOUND");

            const cvButton = cvToggle.querySelector('button[value="CV"]');
            if (cvButton) {
              cvButton.click();
              cvButton.dispatchEvent(
                new MouseEvent("click", { bubbles: true, cancelable: true })
              );

              setTimeout(async () => {
                try {
                  const styles = await extractStyles(iframeDocument);
                  const updatedHTML = iframeDocument.documentElement.outerHTML;

                  const parser = new DOMParser();
                  const doc = parser.parseFromString(updatedHTML, "text/html");

                  // Fix images and fonts
                  fixRelativePaths(doc, baseUrl);
                  await convertImagesToBase64(doc);

                  const cvHtml = doc.querySelector(".sc-bXWofN.hLfVLk");
                  if (cvHtml) {
                    const styledHTML = `
                                        <html>
                                        <head>${styles}</head>
                                        <body>${cvHtml.outerHTML}</body>
                                        </html>
                                    `;
                    resolve(styledHTML);
                  } else {
                    reject("❌ CV section not found.");
                  }
                } catch (error) {
                  reject(error);
                }
              }, 3000);
            } else {
              reject("❌ CV button NOT FOUND inside .cv-toggle.");
            }
          }, 2000);
        } catch (error) {
          reject(error);
        }
      };

      document.body.appendChild(iframe);
    });
  };

  // Helper functions for pdf generation
  const fixRelativePaths = (doc, baseUrl) => {
    doc.querySelectorAll("img").forEach((img) => {
      if (!img.src.startsWith("http")) {
        img.src = new URL(img.getAttribute("src"), baseUrl).href;
      }
    });
  };

  const convertImagesToBase64 = async (doc) => {
    const elementsWithBackground = doc.querySelectorAll(
      "[style*='background-image']"
    );

    const promises = Array.from(elementsWithBackground).map(async (element) => {
      const style = element.style.backgroundImage;
      const urlMatch = style.match(/url\(["']?(.*?)["']?\)/);

      if (urlMatch) {
        const imageUrl = urlMatch[1];

        try {
          const response = await fetch(imageUrl, { mode: "cors" });
          const blob = await response.blob();
          const reader = new FileReader();

          return new Promise((resolve) => {
            reader.onloadend = () => {
              element.style.backgroundImage = `url(${reader.result})`; // ✅ Replace URL with Base64
              resolve();
            };
            reader.readAsDataURL(blob);
          });
        } catch (error) {
          console.error(
            "❌ Failed to convert background image:",
            imageUrl,
            error
          );
        }
      }
    });

    await Promise.all(promises);
  };

  const extractStyles = async (iframeDocument) => {
    let cssText = "";

    // Fetch inline styles from <style> tags
    iframeDocument.querySelectorAll("style").forEach((style) => {
      cssText += style.innerHTML;
    });

    // Fetch linked stylesheets
    const stylesheetPromises = Array.from(
      iframeDocument.querySelectorAll('link[rel="stylesheet"]')
    ).map((link) =>
      fetch(link.href)
        .then((res) => res.text())
        .catch(() => "")
    );

    const styles = await Promise.all(stylesheetPromises);
    cssText += styles.join("\n");

    return `<style>${cssText}</style>`;
  };

  useEffect(() => {
    if (formValues?.attachCV) {
      generatePdf();
    } else {
      setPdfBlob(null);
    }
  }, [formValues?.attachCV]);

  useEffect(() => {
    if (formValues) {
      const requiredFields = [
        "email",
        "firstName",
        "lastName",
        "message",
        "subject",
        "typeOfContact"
      ];
      const isFilled = requiredFields
        .map(k => !!formValues[k])
        .every(k => k === true);
      const enabled = isFilled && !invalid;
      setIsDisabled(!enabled);
    }
  }, [formValues]);

  const onCancel = () => {
    modals.confirm({
      text: "Do you want to delete this draft?",
      accept: onClose
    });
  };

  // const onSubmit = data => {
  //   const preparedData = {
  //     ...data,
  //     typeOfContact: normalizeSelectValue(data.typeOfContact)
  //   };
  //   const doesAccessExist = accesses.find(a => a.email === data.email || null);

  //   if (!!doesAccessExist) {
  //     modals.confirm({
  //       text: `Do you want to resend access to email that already exist ${data.email} ?`,
  //       accept: () => dispatch(createAccess(resio.id, preparedData, onClose))
  //     });
  //   } else {
  //     dispatch(createAccess(resio.id, preparedData, onClose));
  //   }
  // };
  const onSubmit = async (data) => {
    try {
      const shouldAttachCV = formValues?.attachCV;
console.log(shouldAttachCV, "shouldAttachCV");
      if (shouldAttachCV && !pdfBlob) {
        console.error("❌ PDF not ready yet!");
        return;
      }

      const formData = new FormData();

      if (shouldAttachCV) {
        formData.append("cvPdf", pdfBlob, "cv.pdf");
      }

      formData.append("data", JSON.stringify(data));
      formData.append(
        "typeOfContact",
        normalizeSelectValue(data.typeOfContact)
      );

      const doesAccessExist = accesses.find(
        (a) => a.email === data.email || null
      );

      if (!!doesAccessExist) {
        modals.confirm({
          text: `Do you want to resend access to email that already exists for ${data.email}?`,
          accept: () => dispatch(createAccess(resio.id, formData, onClose)),
        });
      } else {
        dispatch(createAccess(resio.id, formData, onClose));
      }
    } catch (error) {
      console.error("❌ Error in form submission:", error);
    }
  };

  const onPreview = () => {
    const preparedData = {
      ...formValues,
      typeOfContact: normalizeSelectValue(formValues.typeOfContact)
    };

    dispatch(requestAccessEmailPreview(resio.id, preparedData));
  };

  useEffect(() => {
    if (isOpen) {
      initialize({
        attachCV: false,
        copyToMe: false
      });
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Apply for jobs/share Resio"
      onRequestClose={onCancel}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Frame
          header="Apply for jobs/share Resio"
          fluid
          notStyledFooter
          footer={
            <StyledFormButtons
              onPreview={onPreview}
              onCancel={onCancel}
              submitDisabled={isDisabled || isPdfGenerating}
              submitLabel={isPdfGenerating ? "Generating PDF..." : "Send"}
              loadingWithoutText
              //loading={isFetching}
              previewLoading={isEmailPreviewFetching}
              
              noMargins
              leftSide={
                <StyledLeftSide>
                  <span>Attach CV</span>
                  <Field name="attachCV" component={ToggleField} />

                  <span className="copy">Copy to me</span>
                  <Field name="copyToMe" component={CheckboxField} white />
                </StyledLeftSide>
              }
            />
          }
        >
          <StyledHeading>
            Compose your email and include as much contact information as
            possible so you can track and manage access. You can preview your
            email before sending using the button below.
          </StyledHeading>

          <StyledHeading>
            We will notify you by email when your Resio has been viewed.
          </StyledHeading>

          {/* <StyledHeading>
            Sharing your Resio by email means that this person/organisation will
            be able to view it through their unique link.
          </StyledHeading> */}
          {/* 
          <StyledHeading>
            Input their contact information to manage access and we will notify
            you when your Resio has been viewed.
          </StyledHeading> */}

          <Field
            name="firstName"
            component={TextField}
            label="First Name"
            validate={[minLength2, maxLength2048]}
          />

          <Field
            name="lastName"
            component={TextField}
            label="Last Name"
            validate={[minLength2, maxLength2048]}
          />

          <Field
            name="organisation"
            component={TextField}
            label="Organisation"
            validate={[minLength2, maxLength2048]}
          />

          <Field
            name="typeOfContact"
            label="Type of Contact"
            component={SelectField}
            required
          >
            <option value="" disabled />
            <option value="Employer">Employer</option>
            <option value="Recruitment Agency">Recruitment Agency</option>
            <option value="Advisor">Advisor</option>
            <option value="Friend">Friend</option>
            <option value="Other">Other</option>
          </Field>

          <Field
            name="email"
            component={TextField}
            type="email"
            label="Email"
            validate={[required, email, maxLength2048]}
            required
          />

          <Field
            name="subject"
            component={TextField}
            label="Email Subject – E.g. the vacancy you are applying for"
            validate={[required, minLength2, maxLength2048]}
            required
          />

          <p>Message *</p>

          <Field
            name="message"
            component={StyledTextEditor}
            label={`Write your covering email message here.

If you are applying for a job it is useful to include:
            
1. What vacancy you are applying for
2. How you found out about it
3. A brief overview of why you should be considered E.g. relevant experience, specific skills, interest in the company or industry
4. Mention one specific achievement relevant to the role
5. State why you are applying and why you would fit with their organisation
6. Say when you would be available for interview if selected`}
            validate={[required, minLength2, maxLength4096]}
            required
          />
        </Frame>
      </form>
    </Modal>
  );
};

export default reduxForm({
  form: "inviteModal",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(InviteModal);
