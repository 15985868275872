import styled from "styled-components";

export const PopupContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
  border-radius: 0.3125em;
  background: #fff;
  font-family: "Mulish", sans-serif;
  font-size: 1rem;
  box-sizing: border-box;
  margin: 0 auto;
`;

export const AvatarCropWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
`;

export const EllipsizedRow = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 310px;
`;

