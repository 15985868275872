import React, { useMemo, useState } from "react";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

const styles = (theme) => ({
  input: {
    display: "flex",
    padding: "5px 0 5px 0px",
    height: "auto",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
  },
  chip: {
    margin: `0.3rem 0.1rem`,
    background: "#37BC9B",
    color: "#fff",
    borderRadius: "5pt"

  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
    paddingLeft: 8,
  },
  placeholder: {
    position: "absolute",
    left: 12,
    fontSize: 16,
  },
  paper: {
    marginTop: theme.spacing.unit,
  },
  disabled: {
    color: "#747474",
  },
  label: {
    color: "#a7a7a7",
  },
});

const NoOptionsMessage = (props) => (
  <Typography
    color="textSecondary"
    className={props.selectProps.classes.noOptionsMessage}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

const inputComponent = ({ inputRef, ...props }) => (
  <div ref={inputRef} {...props} />
);

const Control = (props) => (
  <TextField
    fullWidth
    sx={{
      "& .MuiInputBase-input": {
        height: "auto",
        display: "flex",
        padding: "5px",
      },
    }}
    InputProps={{
      inputComponent,
      inputProps: {
        className: props.selectProps.classes.input,
        inputRef: props.innerRef,
        children: props.children,
        ...props.innerProps,
      },
    }}
    {...props.selectProps.textFieldProps}
  />
);

const Option = (props) => (
  <MenuItem
    buttonRef={props.innerRef}
    selected={props.isFocused}
    component="div"
    style={{
      fontWeight: props.isSelected ? 500 : 400,
    }}
    className={props.isDisabled ? props.selectProps.classes.disabled : ""}
    {...props.innerProps}
  >
    {props.children}
  </MenuItem>
);

const Placeholder = (props) => (
  <Typography
    color="textSecondary"
    className={props.selectProps.classes.placeholder}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

const SingleValue = (props) => (
  <Typography
    className={props.selectProps.classes.singleValue}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

const ValueContainer = (props) => (
  <div className={props.selectProps.classes.valueContainer}>
    {props.children}
  </div>
);

const MultiValue = (props) => {
  let onDelete =
    props.data.isClearable || props.data.isClearable === undefined
      ? (event) => {
        props.removeProps.onClick();
        props.removeProps.onMouseDown(event);
      }
      : null;

  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={props.selectProps.classes.chip}
      onDelete={onDelete}
      sx={{
        "&.MuiButtonBase-root": {
          color: "#fff",
          margin: "0.3rem 0.1rem",
          background: "#37BC9B",
        },
      }}
    />
  );
};

const Menu = (props) => (
  <Paper
    square
    className={props.selectProps.classes.paper}
    {...props.innerProps}
  >
    {props.children}
  </Paper>
);

const components = {
  Option,
  Control,
  NoOptionsMessage,
  Placeholder,
  SingleValue,
  MultiValue,
  ValueContainer,
  Menu,
};

// Restriction: Max characters for Work Skills
const MAX_LENGTH_WORKSKILL_TAG = 30;

const CreateField = ({
  classes,
  theme,
  input,
  label,
  options,
  isMulti,
  isClearable,
  defaultValue,
  noOptionsMessage,
  placeholder,
  disableCreateNew,
}) => {
  const selectStyles = {
    input: (base) => ({
      ...base,
      height: "auto",
      display: "flex",
      padding: "5px",
      color: theme.palette.text.primary,
    }),
  };

  const SelectComponent = useMemo(
    () => (disableCreateNew ? Select : Creatable),
    [disableCreateNew]
  );

  // 🚀 State to track input and prevent exceeding limit
  const [inputValue, setInputValue] = useState("");

  // Function to limit character count dynamically
  const handleInputChange = (newValue, actionMeta) => {
    if (newValue.length > MAX_LENGTH_WORKSKILL_TAG) {
      return inputValue; // Prevent updates beyond limit
    }
    setInputValue(newValue);
    return newValue;
  };

  return useMemo(
    () => (
      <div className={classes.root} style={{ marginBottom: "0.5rem" }}>
        <SelectComponent
          classes={classes}
          styles={selectStyles}
          clearable={false}
          textFieldProps={{
            label,
            InputLabelProps: {
              shrink: true,
              className: classes.label,
            },
          }}
          options={options}
          components={components}
          {...input}
          onBlur={() => { }}
          value={input.value || defaultValue || []}
          isClearable={isClearable}
          defaultValue={defaultValue}
          isMulti={isMulti}
          inputValue={inputValue} // Controlled Input
          onInputChange={handleInputChange} // Restrict length
          placeholder={placeholder}
          noOptionsMessage={(value) =>
            value?.length > MAX_LENGTH_WORKSKILL_TAG
              ? `Max ${MAX_LENGTH_WORKSKILL_TAG} characters allowed`
              : noOptionsMessage
                ? noOptionsMessage(value)
                : "Start typing to add an entry"
          }
        />
      </div>
    ),
    [input.value, defaultValue, options, isMulti, isClearable, noOptionsMessage, inputValue]
  );
};

export default withStyles(styles, { withTheme: true })(CreateField);
