import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { selectors as resioSelectors } from "../../redux/modules/resios";
import {
  requestResio,
  updateResio,
  selectors
} from "../../redux/modules/resio";
import { requestAccesses } from "../../redux/modules/access/accessActions";
import { requestReferenceRequests } from "../../redux/modules/referenceRequests/referenceRequestsActions";
import { logout } from "../../redux/modules/auth";
import errorHandlers from "../../services/errorHandlers";

import contentTypes from "../../constants/contentTypes";
import { constants, media } from "../../styles";
import Sidebar from "../../containers/Sidebar";
import TileGrid from "../../containers/TileGrid";
import PageNotFound from "../../components/PageNotFound";
import About from "../../containers/About";
import IdealOpportunity from "../../containers/IdealOpportunity";
import Experience from "../../containers/Experience";
import AddExperience from "../../containers/AddExperience";
import EditExperience from "../../containers/EditExperience";
import Education from "../../containers/Education";
import Contact from "../../containers/Contact";
import Awards from "../../containers/Awards";
import AddAward from "../AddAward";
import AddEducation from "../AddEducation";
import EditAward from "../EditAward";
import EditEducation from "../EditEducation";
import SoftSkills from "../SoftSkills";
import AddSoftSkill from "../AddSoftSkill";
import EditSoftSkill from "../EditSoftSkill";
import WorkSkills from "../WorkSkills";
import AddWorkSkill from "../AddWorkSkill";
import EditWorkSkill from "../EditWorkSkill";
import References from "../References";
import EditReference from "../EditReference";
import Media from "../Media";
import AddMedia from "../AddMedia";
import EditMedia from "../EditMedia";
import Share from "../Share";
import MobileFooter from "../../components/MobileFooter";
import Spinner from "../../components/Spinner";
import SentryBoundary from "../../components/SentryBoundary";
import WelcomeModal from "../../components/WelcomeModal";
import SalesModal from "../../components/SalesModal";
import idealOpportunities from "../../constants/idealOpportunities";
import AddExternalMedia from "../AddExternalMedia";
import RequestReference from "../../containers/RequestReference";

const PaddedContainer = styled.div`
  padding: 0.5rem 0.2rem;
  ${media.tablet`padding: 2rem;`};
`;

class ResioEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      isIdealEmloyerDataUpdated: false
    };
  }

  requests = () => {
    const { resioId } = this.props.match.params;
    if (!resioId) {
      return;
    }
    const {
      requestResio,
      requestAccess,
      requestReferenceRequests
    } = this.props;

    requestResio(resioId);
    requestAccess(resioId);
    requestReferenceRequests(resioId);
  };

  componentDidMount() {
    const { resios, location } = this.props;
    const isModalOpen = resios.length === 0 || location?.pathname === "/new";
    this.setState({
      ...this.state,
      isModalOpen
    });

    this.requests();
  }

  componentDidUpdate(pp) {
    if (pp.match.params.resioId !== this.props.match.params.resioId) {
      this.requests();
    }

    if (this.props.isAccessFailed || this.props.isReferenceRequestFailed) {
      console.error("Authentification failed"); // for easier debugging
      errorHandlers.showDialog("Authentification failed");
      this.props.logout();
    }

    const updateidealOpportunities = () => {
      const { resio, updateResio } = this.props;
      const currentOpportunities =
        resio && resio.idealOpportunities && resio.idealOpportunities.length > 0
          ? resio.idealOpportunities
          : null;

      if (currentOpportunities) {
        const opportunities = idealOpportunities.reduce((acc, io) => {
          acc[io.value] = true;
          return acc;
        }, {});
        const isUpdated = currentOpportunities
          .map(co => {
            if (!opportunities[co]) {
              return null;
            }
          })
          .every(value => value !== null);

        if (!isUpdated && !this.state.isIdealEmloyerDataUpdated) {
          this.setState({
            ...this.state,
            isIdealEmloyerDataUpdated: true
          });
          const newOpportunities = Object.keys(opportunities);
          updateResio(resio.id, {
            ...resio,
            idealOpportunities: newOpportunities
          });
        }
      }
    };

    updateidealOpportunities();
  }

  onModalClose = () => {
    const { history, location } = this.props;
    if (location?.pathname === "/new") {
      history.goBack();
    }
    this.setState({ ...this.state, isModalOpen: false });
  };

  render() {
    const {
      className,
      title,
      match,
      resio,
      resios,
      isFetching,
      location
    } = this.props;

    return (
      <>
     
        <WelcomeModal
          isOpen={resios.length === 0}
          match={match}
          onClose={this.onModalClose}
        />
        <SalesModal
          isOpen={location?.pathname === "/new"}
          onClose={this.onModalClose}
        />
        <div className={className}>
          <Sidebar />
          <Helmet>
            <title>{title || "Shell"}</title>
          </Helmet>
          <SentryBoundary key={location.pathname}>

            <PaddedContainer>
              {isFetching ? (
                <Spinner />
              ) : (
                <Switch>

                  <Route exact path={match.url} component={TileGrid} />
                  <Route
                    path={`${match.url}${contentTypes.aboutMe.url}`}
                    component={About}
                  />
                  <Route
                    path={`${match.url}${contentTypes.idealOpportunity.url}`}
                    component={IdealOpportunity}
                  />
                  <Route
                    path={`${match.url}${contentTypes.experience.url}/add`}
                    component={AddExperience}
                  />
                  <Route
                    path={`${match.url}${contentTypes.awards.url}/add`}
                    component={AddAward}
                  />
                  <Route
                    path={`${match.url}${contentTypes.education.url}/add`}
                    component={AddEducation}
                  />
                  <Route
                    path={`${match.url}${contentTypes.softSkills.url}/add`}
                    component={AddSoftSkill}
                  />
                  <Route
                    path={`${match.url}${contentTypes.workSkills.url}/add`}
                    component={AddWorkSkill}
                  />
                  <Route
                    path={`${match.url}${contentTypes.references.url}/add`}
                    component={EditReference}
                  />
                  <Route
                    path={`${match.url}${contentTypes.references.url}/request`}
                    component={RequestReference}
                  />
                  <Route
                    path={`${match.url}${contentTypes.media.url}/add`}
                    component={AddMedia}
                  />
                  <Route
                    path={`${match.url}${contentTypes.media.url}/add-external`}
                    component={AddExternalMedia}
                  />
                  <Route
                    path={`${match.url}${contentTypes.contact.url}`}
                    component={Contact}
                  />
                  <Route
                    path={`${match.url}${contentTypes.experience.url}/:index`}
                    render={({ match }) => {
                      const entryToEdit =
                        resio &&
                        resio.experience &&
                        resio.experience[match.params.index];

                      if (entryToEdit) {
                        return (
                          <EditExperience
                            index={match.params.index}
                            initialValues={entryToEdit}
                          />
                        );
                      }

                      if (resio && resio.id) {
                        return (
                          <Redirect
                            to={`/edit/${resio.id}${contentTypes.experience.url}`}
                          />
                        );
                      }

                      return <Redirect to="/" />;
                    }}
                  />
                  <Route
                    path={`${match.url}${contentTypes.awards.url}/:index`}
                    render={({ match }) => {
                      const entryToEdit =
                        resio &&
                        resio.awards &&
                        resio.awards[match.params.index];

                      if (entryToEdit) {
                        return (
                          <EditAward
                            index={match.params.index}
                            initialValues={entryToEdit}
                          />
                        );
                      }

                      if (resio && resio.id) {
                        return (
                          <Redirect
                            to={`/edit/${resio.id}${contentTypes.awards.url}`}
                          />
                        );
                      }

                      return <Redirect to="/" />;
                    }}
                  />
                  <Route
                    path={`${match.url}${contentTypes.education.url}/:index`}
                    render={({ match }) => {
                      const entryToEdit =
                        resio &&
                        resio.education &&
                        resio.education[match.params.index];

                      if (entryToEdit) {
                        return (
                          <EditEducation
                            index={match.params.index}
                            initialValues={entryToEdit}
                          />
                        );
                      }
                      
                      if (resio && resio.id) {
                        return (
                          <Redirect
                            to={`/edit/${resio.id}${contentTypes.education.url}`}
                          />
                        );
                      }

                      return <Redirect to="/" />;
                    }}
                  />
                  <Route
                    path={`${match.url}${contentTypes.softSkills.url}/:index`}
                    render={({ match }) => {
                      const entryToEdit =
                        resio &&
                        resio.softSkills &&
                        resio.softSkills.updatedSoftSkills[match?.params?.index || 0];


                      if (entryToEdit) {
                        return (
                          <EditSoftSkill
                            index={match.params.index}
                            initialValues={entryToEdit}
                          />
                        );
                      }

                      if (resio && resio.id) {
                        return (
                          <Redirect
                            to={`/edit/${resio.id}${contentTypes.softSkills.url}`}
                          />
                        );
                      }

                      return <Redirect to="/" />;
                    }}
                  />
                  <Route
                    path={`${match.url}${contentTypes.workSkills.url}/:index`}
                    render={({ match }) => {
                      const entryToEdit =
                        resio &&
                        resio.workSkills &&
                        resio.workSkills[match.params.index];

                      if (entryToEdit) {
                        return (
                          <EditWorkSkill
                            index={match.params.index}
                            initialValues={entryToEdit}
                          />
                        );
                      }

                      if (resio && resio.id) {
                        return (
                          <Redirect
                            to={`/edit/${resio.id}${contentTypes.workSkills.url}`}
                          />
                        );
                      }

                      return <Redirect to="/" />;
                    }}
                  />
                  <Route
                    path={`${match.url}${contentTypes.references.url}/:index`}
                    render={({ match }) => {
                      const entryToEdit =
                        resio &&
                        resio.references &&
                        resio.references[match.params.index];

                      if (entryToEdit) {
                        return (
                          <EditReference
                            index={match.params.index}
                            initialValues={entryToEdit}
                          />
                        );
                      }

                      if (resio && resio.id) {
                        return (
                          <Redirect
                            to={`/edit/${resio.id}${contentTypes.references.url}`}
                          />
                        );
                      }

                      return <Redirect to="/" />;
                    }}
                  />
                  <Route
                    path={`${match.url}${contentTypes.media.url}/:index`}
                    render={({ match }) => {
                      const entryToEdit = resio &&
                        resio.media &&
                        resio.media[match.params.index] && {
                          name: resio.media[match.params.index].name,
                          description:
                            resio.media[match.params.index].description,
                          url: resio.media[match.params.index].url
                        };

                      if (entryToEdit) {
                        return (
                          <EditMedia
                            index={match.params.index}
                            initialValues={entryToEdit}
                          />
                        );
                      }

                      if (resio && resio.id) {
                        return (
                          <Redirect
                            to={`/edit/${resio.id}${contentTypes.media.url}`}
                          />
                        );
                      }

                      return <Redirect to="/" />;
                    }}
                  />
                  <Route
                    path={`${match.url}${contentTypes.experience.url}`}
                    component={Experience}
                  />
                  <Route
                    path={`${match.url}${contentTypes.education.url}`}
                    component={Education}
                  />
                  <Route
                    path={`${match.url}${contentTypes.awards.url}`}
                    component={Awards}
                  />
                  <Route
                    path={`${match.url}${contentTypes.softSkills.url}`}
                    component={SoftSkills}
                  />
                  <Route
                    path={`${match.url}${contentTypes.workSkills.url}`}
                    component={WorkSkills}
                  />
                  <Route
                    path={`${match.url}${contentTypes.references.url}`}
                    component={References}
                  />
                  <Route
                    path={`${match.url}${contentTypes.media.url}`}
                    component={Media}
                  />
                  <Route path={`${match.url}/share`} component={Share} />
                  <Route component={PageNotFound} />
                </Switch>
              )}
            </PaddedContainer>
          </SentryBoundary>
          <MobileFooter id={this.props.match.params.resioId} />
        </div>
      </>
    );
  }
}

ResioEdit = styled(ResioEdit)`
  padding-left: 0;
  padding-bottom: ${constants.footerHeight};

  ${media.tablet`
    padding-bottom: 0;
    padding-left:
      ${props =>
        props.sidebar
          ? constants.sidebarFullWidth
          : constants.sidebarCollapsedWidth};
  `};

  transition: padding 0.3s ease-out;
`;

const mapStateToProps = state => ({
  resios: resioSelectors.getResios(state),
  resio: selectors.getResio(state),
  title: selectors.getTitle(state),
  isFetching: selectors.getFetching(state),
  sidebar: state.sidebar,
  isAccessFailed: state.access.isFailed,
  isReferenceRequestFailed: state.referenceRequests.isFailed
});

const mapDispatchToProps = dispatch => ({
  requestResio: id => {
    dispatch(requestResio(id));
  },
  requestAccess: id => {
    dispatch(requestAccesses(id));
  },
  requestReferenceRequests: id => {
    dispatch(requestReferenceRequests(id));
  },
  updateResio: (id, data) => {
    dispatch(updateResio(id, data));
  },
  logout: () => {
    dispatch(logout());
  }
});

ResioEdit = connect(mapStateToProps, mapDispatchToProps)(ResioEdit);

export default ResioEdit;
