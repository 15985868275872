import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ClickOutside from "react-click-outside";

import { logout, deleteAccount } from "../../redux/modules/auth";
import { selectors } from "../../redux/modules/resio";
import modals from "../../services/modals";
import Modal from "../Modal";
import Frame from "../Frame";
import ProfilePasswordForm from "../../containers/ProfilePasswordForm";
import { deleteAccountAction } from "../../containers/Profile";

const ProfileHeader = styled(({ className, firstName, isOpen, onClick }) => {
  return(
  <div className={className} to="/profile" title="Profile" onClick={onClick}>
    <span key={isOpen ? "open" : "closed"}>
      <i className={`fas fa-fw fa-caret-${isOpen ? "up" : "down"}`} />
    </span>
    <span>
      <i className="fas fa-user" />
    </span>
    {firstName}
  </div>
)})`
  height: 5rem;

  padding-right: 1.5rem;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  display: inline-block;
  padding-top: 36px;
  box-sizing: border-box;

  @media (max-width: 880px) {
    max-width: 200px;
  }

  &:visited {
    color: inherit;
  }

  > :nth-child(2) {
    margin: 0 0.5rem;
  }
`;

let ProfileActionsList = ({
  className,
  togglePasswordForm,
  logout,
  deleteAccount,
  onClick,
  resio
}) => (
  <ul className={className} onClick={onClick}>
    <li>
      <Link to={`/edit/${resio.id}`} title="Edit My Resio">
        Edit My Resio
      </Link>
    </li>
    <li>
      <Link to={`/${resio.id}`} title="Preview My Resio">
        Preview My Resio
      </Link>
    </li>
    <li>
      <Link to={`/edit/${resio.id}/share`} title="Apply for Jobs/Share Resio">
        Apply for Jobs/Share Resio
      </Link>
    </li>
    <li>
      <Link to="/profile" title="Edit My Details">
        Edit My Details
      </Link>
    </li>
    <li>
      <button
        type="button"
        onClick={togglePasswordForm}
        title="Change Password"
      >
        Change Password
      </button>
    </li>
    <li>
      <Link to="/agreement" title="User Agreement">
        User Agreement
      </Link>
    </li>
    <li>
      <Link to="/privacy" title="Privacy Policy">
        Privacy Policy
      </Link>
    </li>
    <li>
      <button type="button" title="Logout" onClick={logout}>
        Logout
      </button>
    </li>
    <li>
      <button type="button" title="Delete Account" onClick={deleteAccount}>
        Delete Account
      </button>
    </li>
  </ul>
);

const actionsListMapStateToProps = state => ({
  resio: selectors.getResio(state)
});

ProfileActionsList = connect(actionsListMapStateToProps)(ProfileActionsList);

ProfileActionsList = styled(ProfileActionsList)`
  min-width: 275px;
  margin: 0;
  padding: 0.25rem 0;
  list-style: none;

  li {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid rgba(245, 247, 250, 0.075);

    &:last-child {
      margin-top: 1.5rem;
      border: 0;
    }
  }

  a {
    display: block;
    color: #fff;
    text-decoration: none;
    font-family: Mulish, sans-serif;

    &:hover {
      text-decoration: underline;
    }
  }

  button {
    -webkit-appearance: none;
    background: none;
    border: none;
    color: white;
    margin: 0;
    padding: 0;
    font-size: initial;

    &:hover {
      text-decoration: underline;
    }
  }
`;

class ProfileDropdown extends React.Component {
  static propTypes = {
    firstName: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      showPasswordForm: false
    };
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.togglePasswordForm = this.togglePasswordForm.bind(this);
    this.logout = this.logout.bind(this);
    this.deleteAccount = this.deleteAccount.bind(this);
  }

  toggleDropdown() {
    this.setState(ps => ({
      isOpen: !ps.isOpen,
      showPasswordForm: ps.isOpen && ps.showPasswordForm
    }));
  }

  togglePasswordForm() {
    this.setState(ps => ({
      showPasswordForm: !ps.showPasswordForm
    }));
  }

  logout() {
    modals.confirm({
      text: "Logout?",
      accept: this.props.logout
    });
  }

  async deleteAccount() {
    deleteAccountAction(this.props.deleteAccount);
  }

  render() {
    const { className, firstName } = this.props;
    const { isOpen, showPasswordForm } = this.state;

    return (
      <div
        onClickOutside={() => this.state.isOpen && this.toggleDropdown()}
      >
      
        <div
          className={className}
          style={{ background: isOpen ? "#656d78" : "transparent" }}
        >
          <ProfileHeader
            firstName={firstName}
            isOpen={isOpen}
            onClick={this.toggleDropdown}
          />
          {isOpen ? (
            <ProfileActionsList
              togglePasswordForm={this.togglePasswordForm}
              logout={this.logout}
              deleteAccount={this.deleteAccount}
              onClick={this.toggleDropdown}
            />
          ) : null}

          <Modal
            isOpen={showPasswordForm}
            contentLabel="Reset password"
            onRequestClose={this.togglePasswordForm}
          >
            <Frame header="Reset password" fluid>
              <ProfilePasswordForm onSubmit={this.togglePasswordForm} />
            </Frame>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  logout: () => {
    dispatch(logout());
  },
  deleteAccount: password => {
    dispatch(deleteAccount(password));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(
  styled(ProfileDropdown)`
    position: absolute;
    top: 0;
    right: 0.5rem;
  `
);
