/*
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { StyledDialog, StyledDropzone, StyledButton, StyledButtonDisabled } from './styled';
*/
/*
const FileUploadDialog = () => {
  const [file, setFile] = useState(null);

  const onDrop = (acceptedFiles) => {
    setFile(acceptedFiles[0]);
  };

  const handleUpload = () => {
    // Zde můžete přidat logiku pro odeslání souboru
    console.log('Uploading file:', file);
  };

  const handleCancel = () => {
    setFile(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  
  return (
    <StyledDialog>
      <h2>Upload your CV</h2>
      <StyledDropzone {...getRootProps({ isDragActive })}>
        <input {...getInputProps()} />
        <p>Drag and drop your files here</p>
        {file && <p>File selected: {file.name}</p>}
      </StyledDropzone>
      {file ? (
        <StyledButton onClick={handleUpload}>
          Upload your CV
        </StyledButton>
      ) : (
        <StyledButtonDisabled disabled>
          Upload your CV
        </StyledButtonDisabled>
      )}
      <StyledButton onClick={handleCancel} style={{ marginLeft: '10px' }}>
        Cancel
      </StyledButton>
    </StyledDialog>
  );

 return(
  <h2>Upload your image</h2>  
 );
};
*/


/*
const FileUploadDialog = () => {
  console.log("Rendering FileUploadDialog");
  const [file, setFile] = useState(null);
  
  const onDrop = (acceptedFiles) => {
    console.log('Files dropped:', acceptedFiles);
    setFile(acceptedFiles[0]);
  };

  const handleUpload = () => {
    console.log('Uploading file:', file);
  };

  const handleCancel = () => {
    setFile(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} style={{ border: '2px dashed #ccc', padding: '20px' }}>
      <input {...getInputProps()} />
      <h2>Upload your image</h2>
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>Drag and drop some files here, or click to select files</p>
      )}
      {file && <p>File selected: {file.name}</p>}
      <button onClick={handleUpload}>Upload</button>
      <button onClick={handleCancel}>Cancel</button>
    </div>
  );
};
*/

import React from 'react';
import {StyledButton, SubHeader} from "./styled";
import axios from "../../services/axios";
import PercentageSpinner from '../../components/PercentageSpinner';

import {connect} from "react-redux";
import {createResioFail, createResioSuccess, selectors} from "../../redux/modules/resios";
import InputLabel from "@material-ui/core/InputLabel";

const replacingFileTypes = {
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "application/docx"
}

class FileUploadDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      pristine: true,
      activePanel: 1,
      errorMessage: "",
      loading: false
    };
    this.onDrop = this.onDrop.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDragEnter = this.handleDragEnter.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
  }
  async onDrop(files) {
    console.log('Files dropped:', files);

    var base64String = null

    if (files[0]) {
      try {
        base64String = await this.convertToBase64(files[0]);
        console.log('Jirka typ souboru: ', files[0].type);
        if (files[0].type in replacingFileTypes){
          base64String = base64String.replace(files[0].type, replacingFileTypes[files[0].type]);
        }
        console.log("Base64 string:", base64String);
      } catch (error) {
        console.error("Error converting file to Base64:", error);
      }
    }

    this.setState(
      { file: files[0],
        base64String: base64String, 
        pristine: false
      }, () => {
        console.log('Jirka State after file drop:', this.state);
      });
  }

  convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result); // Base64 string bude v reader.result
      reader.onerror = (error) => reject(error);
    });
  }

  handleUpload = (event) => {
    //event.preventDefault();
    console.log('Uploading file:', this.state.base64String);
    if (this.props.onFileUpload) {
      this.sendToBotX().then(data => {
        console.log('Jirka hotovo: ', data);
        this.setState({errorMessage: ""});
        //this.props.createResioSuccess(data);
        window.mixpanel.people.increment("resios created");
        window.mixpanel.track("User created a new resio", {
          resio_title: data.title
        });
        window.location.href = "/edit/" + data.id;
      }).catch(exception => {
        this.setState({errorMessage: exception.message});
        this.props.createResioFail(exception);
      })
    }
  }
 
  async sendToBotX(){
    //this.props.onFileUpload(this.state.base64String);
    this.setState({loading: true});

    const formData = new FormData();

    console.log("Jirka THIS:", this);

    // Add a text field
    formData.append("uploadedCV", this.state.base64String);
    formData.append("title", this.props.title);
    
      
    return axios
      .post("/resio/botxcv", formData, { withCredentials: true, timeout: 300 * 1000 }, {
        headers: {
          "x-csrf-token": window.sessionStorage.getItem("CSRF-TOKEN"),
          "Content-Type": "multipart/form-data"
        },
      })
      .then(resp => {
        console.log("Jirka Response botx: ", resp);
        console.log("Jirka response timeout: ", resp.config.timeout);
        return resp.data;
      }).catch((exception) => {
        console.error(exception);

        // Kontrola, zda exception obsahuje config a timeout
        if (exception.config && exception.config.timeout) {
          console.error("Timeout during exception:", exception.config.timeout);
        } else {
          console.error("Timeout not available in exception.");
        }

        throw exception;
      })
      .finally(() => {
        this.setState({loading: false});
      }); 
  }

  handleCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  redirectToResio(id){
    axios.get("/edit/" + id);
  }

  handleDragEnter(e) {
    e.preventDefault();
    this.setState({ isDragActive: true });
  }

  handleDragLeave(e) {
    e.preventDefault();
    this.setState({ isDragActive: false });
  }

  handleDragOver(e) {
    e.preventDefault();
  }

  async handleDrop(e) {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);    
    this.onDrop(files);
  }

  render() {
    const { file, pristine, loading, errorMessage } = this.state;

    console.log("Rendering button - file:", file, "pristine:", pristine);

    return (
      <div>
        {loading === false && (
          <div
          onDragEnter={this.handleDragEnter}
          onDragLeave={this.handleDragLeave}
          onDragOver={this.handleDragOver}
          onDrop={this.handleDrop}
          style={{ border: '2px dashed #ccc', padding: '20px' }}
          >
          <input
            name="cv"
            type="file"
            onChange={(e) => this.onDrop(e.target.files)}
            style={{ display: 'none' }}
            ref={(input) => this.inputElement = input}
          />
          <SubHeader>.PDF or .DOCX</SubHeader>
          {file ? (
            <div>
              <p>File selected:</p>
              <p>{file.name}</p>
            </div>
          ): (
            <div>
              <p>You can also upload by</p>
              <p>clicking here</p>            
            </div>
          )}
        </div>
      )}
      {loading === true && (
        <PercentageSpinner className={this.state.loading ? '' : 'hidden'}></PercentageSpinner>
      )}
      <InputLabel
        error={true}
        className={errorMessage ? '' : 'hiddden'}>
          {errorMessage}
      </InputLabel>
      <StyledButton
        style={{
          marginTop: "1.5rem",
          opacity: file || !loading ? 1 : "0.5"
        }}
        type="submit"
        primary
        fluid
        disabled={pristine || !file || loading}
        onClick={this.handleUpload}
      >
        Upload your CV
      </StyledButton>
      <StyledButton
        style={{
          marginTop: "1.5rem",
          opacity: !loading ? 1 : "0.5"
        }}
        type
        secondary
        fluid
        disabled = {loading}
        onClick={this.handleCancel}
      >
        Cancel
      </StyledButton>
    </div>
    );
  }
};

const mapStateToProps = state => ({
  resios: selectors.getResios(state),
  initialValues: {
    copyId: "null"
  }
});

const mapDispatchToProps = dispatch => ({
  createResioSuccess: data => {
    dispatch(createResioSuccess(data));
  },
  createResioFail: data => {
    dispatch(createResioFail(data));
  }
});

FileUploadDialog = connect(mapStateToProps, mapDispatchToProps)(FileUploadDialog);

export default FileUploadDialog;