import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  toggleSignupModal,
  toggleLoginModal,
  toggleRecruiterModal
} from "../../redux/modules/auth";
import Button from "../Button";
import HomeHeader from "../HomeHeader";
import HomeHero from "../HomeHero";
import HomeContentBlock from "../HomeContentBlock";
import HomeTestimonials from "../HomeTestimonials";
import HomeFooter from "../HomeFooter";
import { media, constants } from "../../styles";
import "bootstrap/dist/css/bootstrap-grid.css";
import { withRouter } from "react-router-dom";

class Home extends React.Component {
  constructor(props) {
    super(props);
    document.body.classList.add("bg-white");
  }

  componentWillUnmount() {
    document.body.classList.remove("bg-white");
  }

  componentDidMount() {
    const { location } = this.props.history;

    if (location && location.pathname === "/login") {
      this.props.toggleLoginModal();
    }

    if (location && location.pathname === "/recruiter") {
      this.props.toggleRecruiterModal();
    }
  }

  render() {
    const { className, toggleSignupModal } = this.props;
    return (
      <div className={className ? ` ${className}` : ""}>
        <HomeHeader />
        <div className="container">
          <HomeHero>
            <h1>
              STAND OUT… <br />
              It's how you win <br />
              interviews!
            </h1>
            <p>
              Want your dream job? Create your free interactive Resio CV today.
              It's how employers need to see you!
            </p>
            <Button
              hero
              element={Link}
              to="/signup"
              onClick={toggleSignupModal}
            >
              Create your free account!
            </Button>
          </HomeHero>

          <HomeContentBlock
            section="Be unique and GET NOTICED!"
            color="#29ABE2"
            icon="create"
          >
            <h3>You are so much more than just a CV</h3>
            <p>
              Present yourself, your skills and experience in a way that makes
              employers sit up and take notice! Let them know what drives you
              and what you really want from work. Resio helps you tell your
              unique story. We support you to find happiness with the best
              opportunities throughout your entire career.
            </p>
          </HomeContentBlock>
          <HomeContentBlock
            reverseLayout
            section="Get creative and express yourself"
            color="#946FD6"
            icon="Multimedia"
          >
            <h3>Showcase your achievements in multimedia</h3>
            <p>
              Getting noticed in a crowded jobs market is tough. Resio allows
              you to express your personality and emphasise your personal and
              professional achievements using video and images. Show them who
              you are and why you should be their next hire!
            </p>
          </HomeContentBlock>
          <HomeContentBlock
            section="Get a step ahead with references"
            color="#97D157"
            icon="References"
          >
            <h3>Demonstrate your worth up front</h3>
            <p>
              Employers want to see references, so validate your skills and
              experience before applying for jobs and increase your chance of
              getting an interview. Resio allows you to request and manage your
              references and testimonials directly through the platform.
            </p>
          </HomeContentBlock>
          <HomeContentBlock
            reverseLayout
            section="Apply for jobs and share"
            color="#FF6244"
            icon="Share"
          >
            <h3>Manage everything in one place</h3>
            <p>
              Resio is your career partner. Apply for jobs, track and manage
              your applications and contacts. Create different versions of your
              Resio CV for different sectors/purposes and leverage your networks
              by sharing on social media too.
            </p>
          </HomeContentBlock>
          <HomeContentBlock
            section="Stay notified of progress"
            color="#00BF98"
            icon="Notifications"
          >
            <h3>We let you know the important stuff</h3>
            <p>
              No more frustration that your CV disappears in to the ‘Application
              Black Hole'. We notify you whenever your Resio CV has been viewed
              and whenever you receive references and skills testimonials.
            </p>
          </HomeContentBlock>
          <HomeContentBlock
            reverseLayout
            section="Protect your privacy"
            color="#FEB707"
            icon="Privacy"
          >
            <h3>Everything is under your control</h3>
            <p>
              No more wondering who has access to your information. Allow, pause
              and delete permission to view your Resio CV at any time. Store all
              your career assets securely in one place for free, forever.
            </p>
          </HomeContentBlock>
          <HomeContentBlock
            section="Find a job you love..."
            color="#FF4949"
            icon="Succeed"
          >
            <h3>
              With an employer who <span className="highlighted">loves</span>{" "}
              you back!
            </h3>
            <p>
              Life is too short to be in the wrong job. Find somewhere you
              belong doing something you love! Resio helps you stand out from
              the crowd, get noticed and find the right employers who will suit
              your career aspirations, understand your values and provide the
              right ‘fit’.
            </p>
          </HomeContentBlock>
        </div>
        <HomeTestimonials />
        <HomeFooter />
      </div>
    );
  }
}

Home = styled(Home)`
   margin-top: ${constants.mobileBannerHeight};
  ${media.tablet`margin-top: ${constants.bannerHeight}`};
  & .highlighted {
    color: #FF4949;
  }
  & .home-content {
    h3 {
    margin-top: 6px;
    letter-spacing: -0.84px;
    font-size: xx-large;
    font-weight: 300;
    }

    p {
      font-family: "Mulish", sans-serif;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.5px
      font-style: normal;
      font-weight: 300;
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  toggleSignupModal: e => {
    e && e.preventDefault();
    dispatch(toggleSignupModal());
  },
  toggleLoginModal: e => {
    e && e.preventDefault();
    dispatch(toggleLoginModal());
  },
  toggleRecruiterModal: e => {
    e && e.preventDefault();
    dispatch(toggleRecruiterModal());
  }
});

Home = withRouter(Home);

export default connect(null, mapDispatchToProps)(Home);
