import React from "react";
import styled from "styled-components";

export default styled(({ className, children, style = {} }) => (
  <h2 className={className} style={style}>
    {children}
  </h2>
))`
  color: ${props => props.color || "#3b4650"};
    font-weight: 500;
    font-size: 14px;
    margin: 7px 0;

  @media print {
    margin-top: 24px;
   

    &::after {
      content: "";
      display: block;
      height: 100px;
      margin-bottom: -100px;
    }
  }
`;
