import React from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import find from "lodash/find";
import RouteLeavingGuard from "../../components/CustomPrompt";
import { updateResio, selectors } from "../../redux/modules/resio";
import persistTileIndexes from "../../services/persistTileIndexes";
import workSkills from "../../constants/workSkills";
import contentTypes from "../../constants/contentTypes";
import Frame from "../../components/Frame";
import FormButtons from "../../components/FormButtons";
import CreateField from "../../components/CreateField";
import { GoBackButton } from "../../components/GoBackButton";
import mergeSkills from "../../services/mergeSkills";
import deleteNulls from "../../services/deleteNulls";
import sortByDate from "../../services/sortByDate";
import tileTypes from "../../constants/tileTypes";
import {
  required,
  minLength2,
  maxLength2048,
  maxLength512,
  maxLength50
} from "../../services/formValidators";

export const formatParentSkills = skills => [
  {
    value: "",
    label: "Start typing or scroll to select a work skillset",
    isDisabled: true
  },
  ...skills.map(skill => ({
    value: skill.title,
    label: skill.title
  }))
];


export const formatChildSkills = skills =>
  skills && skills.items
    ? skills.items.map(s => ({
        value: s,
        label: s
      }))
    : [];

const MAX_LENGTH_WORKSKILL_TAG = 30;

class AddWorkSkill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dirt: false,
      skills: []
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  setIsEditing = value => {
    this.setState({ dirt: value });
  };

  async onSubmit(data) {
    this.setIsEditing(false);
    const {
      resio,
      updateResio,
      reset,
      shouldAddToTileGrid,
      addToGridAsDefault,
      history
    } = this.props;

    const { skills } = data;
    const { id, tiles } = resio;

    const redirectRoute =
      resio.workSkills.length > 0 ? `/edit/${id}/work-skills` : "/";

    const update = {
      ...resio,
      workSkills: mergeSkills(
        resio.workSkills.concat({
          title: data.title.value,
          items: skills.map(s => ({
            title: s.value
          }))
        })
      )
    };

    skills && !!skills.length && (await updateResio(resio.id, update));

    history.push(redirectRoute);
  }

  setSkills(skills) {
    this.setState({
      ...this.state,
      skills: skills
    });
  }

  render() {
    const {
      pristine,
      invalid,
      resio,
      handleSubmit,
      title,
      skills,
      userSkills,
      isPosting
    } = this.props;

    const selectedOptions =
      userSkills && userSkills.items
        ? userSkills.items.map(s => ({
            value: s.title,
            label: s.title,
            isClearable: false
          }))
        : [];

    const allowCreation = v => {
      if (!v) return false;
      const value = v["inputValue"] || v;
      return title !== "Languages" && value.length <= MAX_LENGTH_WORKSKILL_TAG;
    };

    return (
      <>
        <GoBackButton
          onClick={() => {
            this.props.history.goBack();
          }}
        />

        <Frame
          header={contentTypes.workSkills.title}
          shadow
          headerBorderColor={contentTypes.workSkills.color}
        >
          <Helmet>
            <title>{contentTypes.workSkills.title}</title>
          </Helmet>

          <RouteLeavingGuard
            when={!!this.state.dirt}
            shouldBlockNavigation={() => !!this.state.dirt}
          />

          <form
            onChange={() => {
              this.setIsEditing(true);
            }}
            onSubmit={handleSubmit(this.onSubmit)}
          >
            <Field
              name="title"
              component={CreateField}
              label="Skillset"
              options={formatParentSkills(workSkills)}
              isMulti={false}
              placeholder="Select Skillset"
              isValidNewOption={allowCreation}
              noOptionsMessage={inputValue =>
                allowCreation(inputValue) ? undefined : "No results found."
              }
            />

            {title && (
              <Field
                name="skills"
                component={CreateField}
                isClearable={false}
                isValidNewOption={allowCreation}
                noOptionsMessage={inputValue =>
                  allowCreation(inputValue) ? undefined : "No results found."
                }
                onChange={e => {
                  this.setSkills(e);
                }}
                label="Skill"
                defaultValue={selectedOptions}
                options={formatChildSkills(skills)}
                placeholder="Enter specific skill - max 30 characters"
                isMulti
              />
            )}

            <FormButtons
              cancelUrl={`/edit/${resio.id}${contentTypes.workSkills.url}`}
              submitDisabled={pristine || invalid || !this.state.skills.length}
              loading={isPosting}
            />
          </form>
        </Frame>
      </>
    );
  }
}

const selector = formValueSelector("addWorkSkill");

const mapStateToProps = state => {
  const resio = selectors.getResio(state);
  const title = selector(state, "title") || workSkills[0].title;
  const titleValue = title ? title.value : "";
  const skills = find(workSkills, ps => ps.title === titleValue);
  const userSkills = find(resio.workSkills, ps => ps.title === titleValue);

  return {
    title: titleValue,
    skills,
    userSkills,
    initialValues: { title },
    resio,
    isPosting: selectors.getPosting(state),
    shouldAddToTileGrid: state.resio.shouldAddToTileGrid
  };
};

const mapDispatchToProps = dispatch => ({
  updateResio: (id, data) =>
    new Promise((res, rej) => {
      dispatch(updateResio(id, data, res, rej));
    })
});

AddWorkSkill = reduxForm({
  form: "addWorkSkill",
  enableReinitialize: true
})(AddWorkSkill);

AddWorkSkill = withRouter(AddWorkSkill);

AddWorkSkill = connect(mapStateToProps, mapDispatchToProps)(AddWorkSkill);

export default AddWorkSkill;
