import React from "react";
import { Field, reduxForm, reset } from "redux-form";
import { connect } from "react-redux";
import { createResio, fillResio, selectors } from "../../redux/modules/resios";

import SelectField from "../../components/SelectField";
import TextField from "../../components/TextField";
import { StyledField, StyledButton } from "./styled";
import {
  required,
  minLength2,
  maxLength2048
} from "../../services/formValidators";
import deleteNulls from "../../services/deleteNulls";
import { withStyles } from "@material-ui/core/styles";
import FileUploadDialog from "../FileUploadDialog";

const styles = () => ({
  input: {
    color: "#424A55"
  }
});

const CustomTextField = withStyles(styles)(TextField);

class NewResioForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      activePanel: 0,
      base64String: null
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onEditTitle = this.onEditTitle.bind(this);
  }

  onSubmit(data) {
    //this.event.preventDefault();
    
    const newData = { copyId: data.copyId, title: data.title , uploadedCV: this.state.base64String};
    deleteNulls(newData);

    const result = this.props.createResio(newData);
  }

  onUploadCvClick = (event) => {
    event.preventDefault();   
    /* const newData = {test: "test"};
    console.log('UPLOAD CV');
    this.props.fillResio(newData); */

    this.changeActivePanel();
  };

  changeActivePanel = () => {
    if (this.state.activePanel === 0){
      this.setState({activePanel:  1})
    } else{
      this.setState({activePanel: 0})
    }

    if (this.props.toggleVisibility) {
      this.props.toggleVisibility();
    }
  };

  onFileUpload = (base64String)=> {
    this.setState({ base64String });
  }

  onEditTitle({ target: { value } }) {
    this.setState({
      ...this.state,
      inputValue: value
    });
  }

  render() {
    const { handleSubmit, invalid, pristine, resios } = this.props;
    const { inputValue, activePanel } = this.state;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        {activePanel === 0 && (
          <div>
          {resios.length > 0 && (
            <Field
              name="copyId"
              component={SelectField}
              label="Copy Existing Resio?"
            >
              <option value="null">Start from scratch</option>
              {resios.map(r => (
                <option key={r.id} value={r.id}>
                  {r.title}
                </option>
              ))}
            </Field>
          )}          
            <StyledField
              name="title"
              inputProps={{ style: { textAlign: "center" } }}
              component={CustomTextField}
              label="Resio Title (e.g. Lead UX Designer)"
              validate={[required, minLength2, maxLength2048]}
              required
              onChange={this.onEditTitle}
            />
            <StyledButton
              style={{
                marginTop: "1.5rem",
                opacity: inputValue ? 1 : "0.5"
              }}
              type
              primary
              fluid
              disabled={pristine || invalid}
              onClick={this.onUploadCvClick}
            >
              Upload my CV to start
            </StyledButton>
            <StyledButton
              style={{
                marginTop: "1.5rem",
                opacity: inputValue ? 1 : "0.5"
              }}
              type="submit"
              primary
              fluid
              disabled={pristine || invalid}
            >
              Start from scratch
            </StyledButton>

          </div>
        )}
        {activePanel === 1 && (
          <FileUploadDialog
          onCancel={this.changeActivePanel}
          onFileUpload={this.onFileUpload}
          title = {this.state.inputValue} />
        )}
      </form>
    );
  }
}

const mapStateToProps = state => ({
  resios: selectors.getResios(state),
  initialValues: {
    copyId: "null"
  }
});

const mapDispatchToProps = dispatch => ({
  createResio: data => {
    dispatch(createResio(data));
  },
  fillResio: data => {
    dispatch(fillResio(data));
  }
});

NewResioForm = reduxForm({
  form: "newResio"
})(NewResioForm);

NewResioForm = connect(mapStateToProps, mapDispatchToProps)(NewResioForm);

export default NewResioForm;
